import { useState, useEffect } from "react";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import DataTable_Component from "../../components/generalstaffs/Data Table/index";
import Status_modal from "../../components/manageInvoice/status_modal/index";
import Add_data_modal from "../../components/generalstaffs/update_data_modal/index";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import create_generalstaffs from "./create_generalstaff";
import {
  faEye,
  faPenToSquare,
  faTrash,
  faEnvelope,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import Layouts from "../../layouts/layouts";
import GeneralStaffDetailModal from "../../components/generalstaffs/GeneralStaff Details";
import * as XLSX from "xlsx"; // Import XLSX library for Excel export

export default function Generalstaff() {
  const title = "General Staff Details";
  const [apistate, setApiState] = useState([]); // Ensure this is an array initially
  const [apicol, setApiCol] = useState([]); // Ensure this is an array initially
  const [selectVal, setSelectval] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectVal_details, setSelectVal_details] = useState([]);
  const [details_modal_show, set_details_modal_Show] = useState(false);
  const [generalStaffCount, setGeneralStaffCount] = useState(0);

  const [open_add_modal, setOpen_add_modal] = useState({
    render: true,
    modal_open: false,
  });

  const [open_update_modal, setOpen_update_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [update_modal_data, setUpdate_modal_data] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [showGeneralStaffModal, setShowGeneralStaffModal] = useState(false);
  const [selectedGeneralStaff, setSelectedGeneralStaff] = useState(null);
  const brad = [{ name: "Home" }, { name: title }];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const columns = [
    {
      name: <b> Name</b>,
      selector: (row) => (row.id_user ? row.id_user.name : "N/A"),
      sortable: true,
    },
    {
      name: <b>City</b>,
      selector: (row) => row.city,
      sortable: true,
    },
    {
      name: <b>Salary</b>,
      selector: (row) => row.salary,
      sortable: true,
    },
    {
      name: <b>Joining Date</b>,
      selector: (row) => formatDate(row.joiningDate),
      sortable: true,
    },
    {
      name: <b> Phone Number</b>,
      selector: (row) => (row.id_user ? row.id_user.phonenumber : "N/A"),
      sortable: true,
    },
    {
      name: <b>Per Packet</b>,
      selector: (row) => row.perpacket,
      sortable: true,
    },
    {
      name: <b>Staff Type</b>,
      selector: (row) =>
        row.id_user && row.id_user.id_generalStaffType
          ? row.id_user.id_generalStaffType.name
          : "N/A",
      sortable: true,
    },
    {
      name: <b>Status</b>,
      selector: (row) => (row.id_user ? row.id_user.status : "N/A"),
      sortable: true,
    },
    {
      name: <b>Action</b>,
      cell: (row) => (
        <>
          <button
            onClick={() => viewGeneralStaff(row)}
            className="btn btn-primary btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
          <button
            onClick={() => setUpdateStoreBtn(row)}
            className="btn btn-info btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </button>
          <button
            onClick={() => delete_row(row)}
            className="btn btn-danger btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      ),
    },
  ];

  const handleShow = (row) => {
    setOpenModal(!openModal);
    setSelectval(row);
  };

  const fetchData = async (page) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/general-staffs?page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (Array.isArray(response.data.generalStaff)) {
        setApiState(response.data.generalStaff);
        setGeneralStaffCount(response.data.generalStaffCount);
      } else {
        console.error("Unexpected data format from API", response.data);
      }
      setApiCol(columns);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]); // Add currentPage to dependencies

  const setStoreBtn = () => {
    setOpen_add_modal({ render: !open_add_modal.render, modal_open: true });
  };

  const setUpdateStoreBtn = (row) => {
    setOpen_update_modal({
      render: !open_update_modal.render,
      modal_open: true,
    });
    setUpdate_modal_data(row);
  };

  const copyLink = () => {
    alert(46);
  };

  const delete_row = (row) => {
    const token = localStorage.getItem("token");
    if (!token) {
      Swal.fire("Unauthorized!");
      return;
    }
    Swal.fire({
      icon: "warning",
      title: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `${process.env.REACT_APP_BASE_URL}/api/v1/general-staff/${row._id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            Swal.fire("Deleted!", "General Staff has been deleted.", "success");
            fetchData(currentPage); // Refresh the data with the current page
          })
          .catch((error) => {
            Swal.fire("Error!", "General Staff could not be deleted.", "error");
          });
      }
    });
  };

  const updateGeneralStaff = (updatedGeneralStaff) => {
    setApiState((prev) =>
      prev.map((generalStaff) =>
        generalStaff._id === updatedGeneralStaff._id
          ? updatedGeneralStaff
          : generalStaff
      )
    );
  };

  const viewGeneralStaff = (generalstaff) => {
    setSelectedGeneralStaff(generalstaff);
    setShowGeneralStaffModal(true);
  };

  const exportToExcel = () => {
    const transformedData = apistate.map((staff) => ({
      Name: staff.id_user ? staff.id_user.name : "N/A",
      "Joining Date": formatDate(staff.joiningDate),
      "Exit Date": formatDate(staff.exitDate),
      Contact: staff.phonenumber,
      "Per Packet": staff.perpacket,
      "User Id": staff.id_user ? staff.id_user.email : "N/A",
      "Alternate Number": staff.homenumber,
      "Temporary Address ": staff.tempaddress,
      "Permannet Address ": staff.permanentaddress,
      "State ": staff.state,
      "City ": staff.city,
      Salary: staff.salary,
      "Description ": staff.description,
    }));

    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "GeneralStaffs");

    XLSX.writeFile(workbook, "general_staffs.xlsx");
  };

  return (
    <>
      <Layouts />
      <Status_modal row={selectVal} openModal={openModal} />
      <Add_data_modal
        select_data={update_modal_data}
        open_update_data_modal={open_update_modal}
        onUpdateGeneralStaff={updateGeneralStaff}
      />
      <GeneralStaffDetailModal
        show={showGeneralStaffModal}
        onHide={() => setShowGeneralStaffModal(false)}
        generalstaff={selectedGeneralStaff}
      />
      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between border-bottom pb-1">
                <h4>{title}</h4>
                <div>
                  <Link to="/create-generalstaffs">
                    <div className="btn btn-info btn-sm text-white me-2">
                      Add Staff
                    </div>
                  </Link>
                  <button
                    onClick={exportToExcel}
                    className="btn btn-success btn-sm text-white"
                  >
                    Export to Excel
                  </button>
                </div>
              </div>
              <div className="card-body">
                {apistate.length > 0 ? (
                  <DataTable_Component
                    searchBy="name" // Search based on the staff's name
                    title_btn={true}
                    title="Staff List"
                    apidata={apistate}
                    columns={apicol}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
