import React, { useState, useEffect, useCallback } from "react";
import DataTable from "react-data-table-component";
import FilterModal from "../Filter Modal/FilterModal";
import axios from "axios";
import moment from "moment";
export default function DataTable_Component({
  apidata,
  columns,
  searchBy,
  onFilterApply,
}) {
  const [dataFilter, setDataFilter] = useState("");
  const [vehicleFilter, setVehicleFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  // Function to validate if a date is valid
  const isValidDate = (date) => {
    return moment(date, "YYYY-MM-DD", true).isValid();
  };

  const fetchData = async () => {
    if (!isValidDate(fromDate) || !isValidDate(toDate)) {
      console.log("Invalid dates provided.");
      return;
    }
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      const formattedFromDate = moment(fromDate).format("YYYY-MM-DD");
      const formattedToDate = moment(toDate).format("YYYY-MM-DD");

      console.log("Fetching data with dates:");
      console.log("FROM DATE", formattedFromDate);
      console.log("TO DATE", formattedToDate);
      const url = `${process.env.REACT_APP_BASE_URL}/api/v1/orders/vehicle-summary/consolidation?dateFrom=${fromDate}&dateTo=${toDate}`;
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const { orderSummary } = response.data;
      if (Array.isArray(orderSummary?.vehicleSummary)) {
        const vehicleData = orderSummary.vehicleSummary.map((vehicle) => ({
          vehicleId: vehicle.vehicleId,
          numberplate: vehicle.numberplate,
          trips: vehicle.trips,
          kmDriven: vehicle.kmDriven,
          amount: vehicle.amount,
          totalOrders: vehicle.totalOrders,
          tripSummary: vehicle.tripSummary,
        }));

        setFilteredData(vehicleData);
      } else {
        console.error(
          "Unexpected data format from API",
          orderSummary?.vehicleSummary
        );
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const debounceFetchData = useCallback(
    debounce(() => {
      fetchData();
    }, 500), // 500ms debounce delay
    [fromDate, toDate]
  );
  useEffect(() => {
    let filtered = apidata || [];

    if (vehicleFilter) {
      filtered = filtered.filter((item) => {
        return (
          item.numberplate &&
          item.numberplate.toLowerCase().includes(vehicleFilter.toLowerCase())
        );
      });
    }

    // Filter by search input (numberplate)
    if (dataFilter) {
      filtered = filtered.filter((item) => {
        if (searchBy === "numberplate") {
          return (
            item.numberplate &&
            item.numberplate.toLowerCase().includes(dataFilter.toLowerCase())
          );
        }
        return false;
      });
    }
    setFilteredData(filtered); // Update filtered data state
  }, [dataFilter, apidata, vehicleFilter, searchBy]);

  const applyFilters = () => {
    if (isValidDate(fromDate) && isValidDate(toDate)) {
      const formattedFromDate = moment(fromDate).format("YYYY-MM-DD");
      const formattedToDate = moment(toDate).format("YYYY-MM-DD");

      console.log("Applying filter with dates:");
      console.log("From Date:", formattedFromDate);
      console.log("To Date:", formattedToDate);

      onFilterApply(formattedFromDate, formattedToDate);
      setShowFilterModal(false);
    } else {
      console.error("Invalid dates provided for applying filters.");
    }
  };

  const removeFilters = () => {
    setFromDate(""); // Clear from date
    setToDate(""); // Clear to date
    setDataFilter(""); // Clear search filter
    setFilteredData(apidata); // Reset to the original unfiltered data
    onFilterApply("", ""); // Notify parent to reset filters (optional)
  };

  return (
    <div className="table-responsive">
      <div className="row justify-content-between mb-2">
        <div className="col-md-3 mb-2">
          <input
            type="text"
            placeholder={`Search by ${searchBy}`}
            className="form-control"
            value={dataFilter}
            onChange={(e) => setDataFilter(e.target.value)}
          />
        </div>
        <div className="col-md-9 mb-2 d-flex align-items-center">
          <button
            className="btn btn-primary me-2"
            onClick={() => setShowFilterModal(true)}
          >
            Filter
          </button>
        </div>
      </div>

      {filteredData.length > 0 ? (
        <DataTable
          columns={columns}
          data={filteredData}
          pagination
          fixedHeader
          highlightOnHover
          responsive
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 20]}
        />
      ) : (
        <div className="row justify-content-start">
          <div className="col-12">
            <p>No results found.</p>
          </div>
        </div>
      )}

      <FilterModal
        show={showFilterModal}
        onHide={() => setShowFilterModal(false)}
        vehicleFilter={vehicleFilter}
        setVehicleFilter={setVehicleFilter}
        applyFilters={applyFilters}
        removeFilters={removeFilters}
        apidata={apidata}
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
      />
    </div>
  );
}

// Utility function for debouncing
function debounce(func, delay) {
  let timeout;
  const debouncedFunc = (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
  debouncedFunc.cancel = () => clearTimeout(timeout);
  return debouncedFunc;
}
