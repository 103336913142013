import React, { useState, useEffect } from "react";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import DataTable_Component from "../../components/orders/Data Table/index";
import Status_modal from "../../components/manageInvoice/status_modal/index";
import Update_data_modal from "../../components/manageInvoice/update_data_modal/index";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import Switch from "react-switch";
import {
  faEye,
  faPenToSquare,
  faTrash,
  faEnvelope,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import Add_data_modal from "../../components/orders/update_data_modal";
import Layouts from "../../layouts/layouts";
import OrderDetailModal from "../../components/orders/Order Details";
import * as XLSX from "xlsx"; // Import XLSX library for Excel export
import CalculatorModal from "./calculator";
import FilterModal from "../../components/orders/Filter Modal/FilterModal";
export default function Order() {
  const title = "Orders -";
  const [showCalculator, setShowCalculator] = useState(false);
  const [apistate, setApiState] = useState([]);
  const [apicol, setApiCol] = useState([]);
  const [selectVal, setSelectval] = useState([]);
  const [openModal, setOpenModal] = useState(true);
  const [selectVal_details, setSelectVal_details] = useState([]);
  const [details_modal_show, set_details_modal_Show] = useState(false);
  const [totalOrdersCount, setTotalOrdersCount] = useState(0);
  const [deliveredCount, setDeliveredCount] = useState(0);
  const [notDeliveredCount, setnotDeliveredCount] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [deliveredOrders, setDeliveredOrders] = useState(0);
  const [undeliveredOrders, setUndeliveredOrders] = useState(0);
  const [amount, setAmount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [open_add_modal, setOpen_add_modal] = useState({
    render: true,
    modal_open: false,
  });

  const [open_update_modal, setOpen_update_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [update_modal_data, setUpdate_modal_data] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [showOrderDetailModal, setShowOrderDetailModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const columns = [
    {
      name: <b>Name</b>,
      selector: (row) => (row.id_user ? row.id_user.name : "N/A"),
      sortable: true,
    },

    {
      name: <b>Vehicle</b>,
      selector: (row) => (row.id_vehicle ? row.id_vehicle.numberplate : "N/A"),
      cell: (row) => (
        <div style={{ whiteSpace: "nowrap", overflow: "visible" }}>
          {row.id_vehicle?.numberplate}
        </div>
      ),
      sortable: true,
    },
    {
      name: <b>Vehicle Type</b>,
      selector: (row) => row.id_vehicle?.id_type?.vehicle_type || "-",
      cell: (row) => (
        <div style={{ whiteSpace: "nowrap", overflow: "visible" }}>
          {row.id_vehicle?.id_type?.vehicle_type || "-"}
        </div>
      ),
      sortable: true,
    },

    {
      name: <b>Total Order</b>,
      selector: (row) => row.dailyOrder,
      sortable: true,
    },
    {
      name: <b>Daily Deliver</b>,
      selector: (row) => row.dailyDeliver,
      sortable: true,
    },
    {
      name: <b>Daily UnDelivered</b>,
      selector: (row) => row.dailyUndelivered,
      sortable: true,
    },

    {
      name: <b>Order Picked </b>,
      selector: (row) => row.orderPicked,
      sortable: true,
    },
    {
      name: <b>Order Un-Picked </b>,
      selector: (row) => row.unPickedOrder,
      sortable: true,
    },
    {
      name: <b>Date</b>,
      selector: (row) => {
        const date = new Date(row.date);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      },
      sortable: true,
    },
    {
      name: <b>KG </b>,
      selector: (row) => row.kg,
      sortable: true,
    },
    {
      name: <b>Cash Short </b>,
      selector: (row) => row.cashSort,
      sortable: true,
    },
    {
      name: <b>Amount </b>,
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: <b>Km's</b>,
      selector: (row) => row.kmDriven,
      sortable: true,
    },
    {
      name: <b>Clients</b>,
      selector: (row) => (row.id_client ? row.id_client.name : "N/A"),
      sortable: true,
    },
    {
      name: <b>Hubs</b>,
      selector: (row) => (row.id_client ? row.id_client.id_hub?.name : "N/A"),
      sortable: true,
    },
    {
      name: <b>Cancel?</b>,
      selector: (row) => (row.cancel ? "True" : "False"),
      cell: (row) => (
        <Switch
          onChange={() => handleToggleIsCancel(row)}
          checked={row.cancel}
        />
      ),
      sortable: true,
    },

    {
      name: <b>Shift Timings</b>,
      cell: (row) => {
        const shift = row.shift?.toLowerCase();
        return (
          <button
            onClick={() => handleShow(row)}
            className={`btn p-0 px-1 ${
              shift === "Morning"
                ? "btn-success"
                : shift === "Afternoon"
                ? "btn-danger"
                : shift === "Evening"
                ? "btn-danger"
                : ""
            }`}
          >
            {row.shift || "Unknown"}
          </button>
        );
      },
    },
    {
      name: <b>Action</b>,
      cell: (row) => (
        <>
          {row.status === "created" && (
            <>
              <button
                onClick={() => copyLink()}
                className=" btn btn-success btn-sm"
              >
                <FontAwesomeIcon icon={faCopy} />
              </button>
              <button className=" btn btn-primary btn-sm ms-1">
                <FontAwesomeIcon icon={faEnvelope} />
              </button>
            </>
          )}
          <button
            onClick={() => setUpdateStoreBtn(row)}
            className=" btn btn-info btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </button>
          <button
            onClick={() => delete_row(row)}
            className=" btn btn-danger btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
          <button
            onClick={() => viewOrder(row)}
            className="btn btn-primary btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
        </>
      ),
    },
  ];

  const handleShow = (row) => {
    setOpenModal(!openModal);
    setSelectval(row);
  };

  const handleToggleIsCancel = async (row) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }

      const updatedCancelStatus = !row.cancel; // Toggle cancel field
      console.log(
        "Sending request to update cancel status:",
        updatedCancelStatus
      );

      const data = {
        cancel: updatedCancelStatus, // Update the 'cancel' field
      };

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/v1/order/${row._id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const updatedApiState = apistate.map((order) =>
          order._id === row._id
            ? { ...order, cancel: updatedCancelStatus } // Ensure 'cancel' is updated
            : order
        );
        setApiState(updatedApiState); // Set state with updated 'cancel' field
        Swal.fire(
          "Updated!",
          "Order cancellation status has been updated.",
          "success"
        );
      } else {
        Swal.fire(
          "Error!",
          "There was an error updating cancel status.",
          "error"
        );
      }
    } catch (error) {
      console.error("Error updating cancel status:", error);
      Swal.fire(
        "Error!",
        "There was an error updating cancel status.",
        "error"
      );
    }
  };

  const fetchOrders = async (page) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }

      const endpoint = `${process.env.REACT_APP_BASE_URL}/api/v1/orders?page=${page}`;
      const response = await axios.get(endpoint, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success && response.data.summary) {
        const { totalOrders, totalDelivered, totalUndelivered, totalAmounts } =
          response.data.summary;
        setTotalOrdersCount(totalOrders);
        setDeliveredCount(totalDelivered);
        setAmount(totalAmounts);
        setUndeliveredOrders(totalUndelivered || 0);
      } else {
        console.error(
          "Summary data is missing in the response from order.js API."
        );
      }

      if (Array.isArray(response.data.orders)) {
        setTotalRecords(response.data.count);
        setApiState(response.data.orders); // Set the orders in state
        setApiCol(columns); // Ensure columns are defined
      } else {
        console.error("Unexpected data format from API", response.data);
      }
    } catch (error) {
      console.log("Error fetching orders:", error);
    }
  };
  useEffect(() => {
    fetchOrders(currentPage);
  }, [currentPage]);

  const setStoreBtn = () => {
    setOpen_add_modal({ render: !open_add_modal.render, modal_open: true });
  };

  const setUpdateStoreBtn = (row) => {
    setOpen_update_modal({
      render: !open_update_modal.render,
      modal_open: true,
    });
    setUpdate_modal_data(row);
  };

  const copyLink = () => {
    alert(46);
  };

  const delete_row = (row) => {
    Swal.fire({
      icon: "warning",
      title: "You wont be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        axios
          .delete(`${process.env.REACT_APP_BASE_URL}/api/v1/order/${row._id}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then(() => {
            Swal.fire("Deleted!", "Order has been deleted.", "success");
            fetchOrders(currentPage);
          })
          .catch((error) => {
            Swal.fire("Error!", "Order could not be deleted.", "error");
            console.error("Error deleting order:", error);
          });
      }
    });
  };

  const updateOrder = (updatedOrder) => {
    setApiState((prev) =>
      prev.map((order) =>
        order._id === updatedOrder._id ? updatedOrder : order
      )
    );
  };

  const handleUpdateOrder = (updatedOrderData) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/v1/order/${updatedOrderData._id}`,
        updatedOrderData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        Swal.fire("Updated!", "Orders details have been updated.", "success");
        updateOrder(response.data.order);
      })
      .catch((error) => {
        Swal.fire("Error!", "Order details could not be updated.", "error");
        console.error(
          "Error updating driver:",
          error.response ? error.response.data : error.message
        );
      });
  };

  const viewOrder = (order) => {
    setSelectedOrder(order);
    setShowOrderDetailModal(true);
  };

  const exportToExcel = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }

      // Call the non-paginated API
      const endpoint = `${process.env.REACT_APP_BASE_URL}/api/v1/orderss`;
      const response = await axios.get(endpoint, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (Array.isArray(response.data.orders)) {
        const transformedData = response.data.orders.map((order, index) => ({
          "S.No": index + 1,
          "User Name": order.id_user ? order.id_user.name : "No User",
          Vehicle: order.id_vehicle?.numberplate || "N/A",
          Client: order.id_client ? order.id_client.name : "No Client",
          "Daily Order": order.dailyOrder || 0,
          "Daily Deliver": order.dailyDeliver || 0,
          "Daily Undeliver": order.dailyUndelivered || 0,
          "Order Picked": order.orderPicked || 0,
          "Order Unpicked": order.unPickedOrder || 0,
          "Cash Short": order.cashSort || 0,
          Amount: order.amount || 0,
          Cancel: order.cancel || "False",
          KG: order.kg || 0,
          KmDriven: order.KmDriven || 0,
          Shift: order.shift || 0,
          Date: moment(order.date).format("YYYY-MM-DD"), // Formatting the date
          Comments: order.comment || "No Comments",
        }));

        // Create Excel workbook
        const worksheet = XLSX.utils.json_to_sheet(transformedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "All Orders");

        // Save the Excel file
        XLSX.writeFile(workbook, "all_orders.xlsx");
      } else {
        console.error("Unexpected data format from API", response.data);
      }
    } catch (error) {
      console.error("Error exporting to Excel:", error); // Debugging point
    }
  };
  const handleDateChange = () => {
    fetchOrders(); // Fetch orders with updated dates
  };
  const handleTotalsChange = ({
    totalOrders,
    deliveredOrders,
    undeliveredOrders,
    amount,
  }) => {
    setTotalOrdersCount(totalOrders);
    setDeliveredCount(deliveredOrders);
    setnotDeliveredCount(undeliveredOrders);
    setAmount(amount);
  };

  return (
    <>
      <Layouts />
      <Status_modal row={selectVal} openModal={openModal} />
      <Add_data_modal
        select_data={update_modal_data}
        open_update_data_modal={open_update_modal}
        onUpdateOrder={updateOrder}
      />
      <OrderDetailModal
        show={showOrderDetailModal}
        onHide={() => setShowOrderDetailModal(false)}
        order={selectedOrder}
      />
      <CalculatorModal
        show={showCalculator}
        onHide={() => setShowCalculator(false)}
      />

      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between align-items-center border-bottom pb-1">
                <h4>{title}</h4>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  {/* <h5>Total Vehicles: {vehicleCount}</h5> */}
                  <div className="d-flex align-items-center">
                    <button
                      className="me-4"
                      style={{
                        backgroundColor: "green",
                        color: "white",
                        borderColor: "white",
                      }}
                    >
                      Total Orders:{totalOrdersCount}{" "}
                    </button>
                    <button
                      className="me-4"
                      style={{
                        backgroundColor: "red",
                        color: "white",
                        borderColor: "white",
                      }}
                    >
                      Delivered Orders:{deliveredCount}{" "}
                    </button>
                    <button
                      className="me-4"
                      style={{
                        backgroundColor: "blue",
                        color: "white",
                        borderColor: "white",
                      }}
                    >
                      Undelivered Orders:{notDeliveredCount}{" "}
                    </button>
                    <button
                      className="me-4"
                      style={{
                        backgroundColor: "slateblue",
                        color: "white",
                        borderColor: "white",
                      }}
                    >
                      Amount:{amount}{" "}
                    </button>
                    <button
                      className="btn btn-primary btn-sm text-white me-2"
                      onClick={() => setShowCalculator(true)}
                    >
                      Open Calculator
                    </button>
                  </div>
                </div>
                <div className="d-flex justify-content-end align-items-center">
                  <div>
                    <Link to="/create-order">
                      <div className="btn btn-info btn-sm text-white me-2">
                        Add Order
                      </div>
                    </Link>
                    <button
                      onClick={exportToExcel}
                      className="btn btn-success btn-sm text-white"
                    >
                      Export to Excel
                    </button>
                  </div>
                </div>
              </div>

              <div className="card-body">
                {apistate.length > 0 ? (
                  <DataTable_Component
                    searchBy="name"
                    apidata={apistate}
                    columns={apicol}
                    setTotalOrderFromOrders={setTotalOrdersCount}
                    setDeliveredCountFromOrders={setDeliveredCount}
                    setUndeliveredOrdersFromOrders={setnotDeliveredCount}
                    setAmountFromOrders={setAmount}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
