import React from "react";
import { Modal, Button } from "react-bootstrap";

const PayoutDetailModal = ({ show, onHide, payout }) => {
  if (!payout) return null;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Payout Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>Hub:</strong> {payout.hubId ? payout.hubId.name : "N/A"}
        </p>
        <p>
          <strong>Client:</strong>{" "}
          {payout.clientId ? payout.clientId.name : "N/A"}
        </p>
        <p>
          <strong>Vehicle Type :</strong> {payout.vehicleType}
        </p>
        <p>
          <strong>Amount :</strong> {payout.amount}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PayoutDetailModal;
