import React from "react";
import { Modal, Button } from "react-bootstrap";

const TypeDetailModal = ({ show, onHide, type }) => {
  if (!type) return null;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Vehicle Type Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>Vehicle Company:</strong> {type.company}
        </p>
        <p>
          <strong>Vehicle Type:</strong> {type.vehiclename}
        </p>
        <p>
          <strong>Wheeler Type:</strong> {type.vehicle_type}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TypeDetailModal;
