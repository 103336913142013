import { useState, useEffect } from "react";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import DataTable_Component from "../../components/ordersreport/Data Table Component/index";
import Status_modal from "../../components/manageInvoice/status_modal/index";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { Bar } from "react-chartjs-2"; // Import the Bar chart
import "chart.js/auto"; // Import Chart.js
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPenToSquare,
  faTrash,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

import Layouts from "../../layouts/layouts";
import Add_data_modal from "../../components/hubs/update_data_modal";
import HubDetailModal from "../../components/hubs/Hub Details";
import { Tooltip } from "react-tooltip";

export default function OrderReport() {
  const title = "Order Hub Report";
  const [apistate, setApiState] = useState([]);
  const [apicol, setApiCol] = useState([]);
  const [selectVal, setSelectval] = useState([]);
  const [openModal, setOpenModal] = useState(true);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [chartData, setChartData] = useState({});
  const [isFiltered, setIsFiltered] = useState(false); // Track if filters are applied

  const [open_add_modal, setOpen_add_modal] = useState({
    render: true,
    modal_open: false,
  });

  const [open_update_modal, setOpen_update_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [update_modal_data, setUpdate_modal_data] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [showHubDetailModal, setShowHubDetailModal] = useState(false);
  const [selectedHub, setSelectedHub] = useState(null);

  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const columns = [
    {
      name: <b>Hub Name</b>,
      selector: (row) => row.hubName,
      sortable: true,
    },
    {
      name: <b>Total Trips/Rides</b>,
      selector: (row) => row.totalTrips,
      sortable: true,
    },
    {
      name: <b>Total Km</b>,
      selector: (row) => row.totalKm,
      sortable: true,
    },
    {
      name: <b>Total Orders</b>,
      selector: (row) => row.totalOrder,
      sortable: true,
    },
    {
      name: <b>Total Delivered</b>,
      selector: (row) => row.totalDeliver,
      sortable: true,
    },
    {
      name: <b>Total UnDelivered</b>,
      selector: (row) => row.totalUndelivered,
      sortable: true,
    },
    {
      name: <b>Total Amount</b>,
      selector: (row) => row.totalAmount,
      sortable: true,
    },
    {
      name: <b>Cash Short</b>,
      selector: (row) => row.totalCashSort,
      sortable: true,
    },
    {
      name: <b>Vehicle Number</b>,
      cell: (row) => {
        const vehicleNumbers =
          row.vehicleSummary
            ?.map((vehicle) => vehicle.numberplate)
            .join(", ") || "N/A";

        return (
          <div style={{ position: "relative", zIndex: 1 }}>
            {vehicleNumbers !== "N/A" ? (
              <>
                <span>{vehicleNumbers.slice(0, 20)}...</span>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  data-tooltip-id={`tooltip-vehicle-${row.hubId}`}
                  data-tooltip-content={vehicleNumbers}
                  style={{ cursor: "pointer", marginLeft: "5px" }}
                />
                <Tooltip
                  id={`tooltip-vehicle-${row.hubId}`}
                  place="bottom"
                  style={{
                    zIndex: 9999,
                    backgroundColor: "black",
                    whiteSpace: "pre-wrap",
                  }}
                />
              </>
            ) : (
              "-"
            )}
          </div>
        );
      },
      sortable: true,
    },
  ];

  const fetchData = async (filterFromDate = "", filterToDate = "") => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }

      let url = `${process.env.REACT_APP_BASE_URL}/api/v1/orders/hub-summary/consolidation`;
      const params = {};

      if (filterFromDate && filterToDate) {
        params.dateFrom = filterFromDate;
        params.dateTo = filterToDate;
      } else {
        // Default: fetch data for the previous month
        const today = new Date();
        const firstDayOfPrevMonth = moment(today)
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        const lastDayOfPrevMonth = moment(today)
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
        params.dateFrom = firstDayOfPrevMonth;
        params.dateTo = lastDayOfPrevMonth;
      }

      const response = await axios.get(url, {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (Array.isArray(response.data.hubSummary)) {
        setApiState(response.data.hubSummary);
      } else {
        console.error(
          "Unexpected data format from API",
          response.data.hubSummary
        );
      }
      setApiCol(columns);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const handleFilterApply = (filterFromDate, filterToDate) => {
    setIsFiltered(true); // Mark that filters are applied
    setFromDate(filterFromDate);
    setToDate(filterToDate);
    fetchData(filterFromDate, filterToDate); // Fetch filtered data
  };
  const handleRemoveFilters = () => {
    setFromDate(""); // Clear from date
    setToDate(""); // Clear to date
    fetchData(); // Fetch data with default date range
  };

  useEffect(() => {
    if (!isFiltered) {
      fetchData();
    }
  }, [isFiltered]);

  const prepareChartData = (data) => {
    if (!data || !Array.isArray(data)) {
      console.error("Invalid data for chart:", data);
      return;
    }

    const hubs = data.map((hub) => hub.hubName || "-"); // Handle missing names
    const trips = data.map((hub) => hub.totalDeliver || 0); // Handle missing trip counts

    // Generate different colors for each stack
    const colors = [
      "rgba(255, 99, 132, 0.6)", // Red
      "rgba(54, 162, 235, 0.6)", // Blue
      "rgba(255, 206, 86, 0.6)", // Yellow
      "rgba(75, 192, 192, 0.6)", // Green
      "rgba(153, 102, 255, 0.6)", // Purple
      "rgba(255, 159, 64, 0.6)", // Orange
    ];

    // Repeat colors if there are more hubs than colors
    const dynamicColors = hubs.map((_, index) => colors[index % colors.length]);

    setChartData({
      labels: hubs,
      datasets: [
        {
          label: "Total Trips",
          data: trips,
          backgroundColor: dynamicColors, // Assign unique colors to each bar
          borderColor: dynamicColors.map((color) => color.replace("0.6", "1")), // Adjust border color opacity
          borderWidth: 1,
        },
      ],
    });
  };

  useEffect(() => {
    prepareChartData(apistate);
  }, [apistate]);

  const setStoreBtn = () => {
    setOpen_add_modal({ render: !open_add_modal.render, modal_open: true });
  };

  const setUpdateStoreBtn = (row) => {
    setOpen_update_modal({
      render: !open_update_modal.render,
      modal_open: true,
    });
    setUpdate_modal_data(row);
  };

  return (
    <>
      <Layouts />
      <Status_modal row={selectVal} openModal={openModal} />
      <Add_data_modal
        select_data={update_modal_data}
        open_update_data_modal={open_update_modal}
      />
      <HubDetailModal
        show={showHubDetailModal}
        onHide={() => setShowHubDetailModal(false)}
        hub={selectedHub}
      />
      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between border-bottom pb-1">
                <h4>{title}</h4>
              </div>
              <div className="card-body">
                <div className="mb-4" style={{ height: "300px" }}>
                  {chartData.labels ? (
                    <Bar
                      data={chartData}
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                          legend: { display: true },
                          title: { display: true, text: "Total Trips per Hub" },
                        },
                      }}
                      height={250}
                    />
                  ) : (
                    <p>Loading chart...</p>
                  )}
                </div>

                {apistate.length > 0 ? (
                  <DataTable_Component
                    searchBy="hubName"
                    title_btn={true}
                    title="Vehicle List"
                    apidata={apistate}
                    columns={apicol}
                    onFilterApply={handleFilterApply}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
