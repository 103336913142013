import { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import Select from "react-select";
import { LoadScript, Autocomplete } from "@react-google-maps/api";

export default function Add_data_modal({
  select_data,
  open_update_data_modal,
  onUpdateVehicle,
}) {
  const [ren, setRen] = useState(false);
  const [update_modal_data, setUpdate_modal_data] = useState(select_data);
  const [add_data_modal_Show, set_update_data_modal_Show] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [hubs, setHubs] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [vendors, setVendors] = useState([]);

  const [autocomplete, setAutocomplete] = useState(null);
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (select_data) {
      setUpdate_modal_data(select_data);

      const selectedDriver = select_data.id_driver.map((item) => ({
        value: item._id,
        label:
          drivers.find((driver) => driver._id === item._id).id_user?.name || "",
      }));
      const selectedHub = select_data.id_hub.map((item) => ({
        value: item._id,
        label: hubs.find((hub) => hub._id === item._id).name || "",
      }));

      setUpdate_modal_data((prevData) => ({
        ...prevData,
        id_driver: [...selectedDriver],
        //set here
        // id_hub: select_data.id_hub._id,
        id_hub: [...selectedHub],
        id_vendor: select_data.id_vendor?._id,
        id_type: select_data.id_type._id,
      }));
    }
  }, [select_data, hubs, vehicleTypes, vendors]);

  useEffect(() => {}, [update_modal_data]);

  useEffect(() => {
    set_update_data_modal_Show(open_update_data_modal.modal_open);
  }, [open_update_data_modal]);

  useEffect(() => {
    const fetchVendors = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/vendors`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setVendors(response.data.vendors || []);
      } catch (error) {
        console.error("Error fetching vendor:", error);
        setVendors([]);
      }
    };
    fetchVendors();
  }, []);

  useEffect(() => {
    const fetchDrivers = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/drivers`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setDrivers(response.data.drivers || []);
      } catch (error) {
        console.error("Error fetching drivers:", error);
      }
    };

    const fetchHubs = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/hubs`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setHubs(response.data.hubs || []);
      } catch (error) {
        console.error("Error fetching hubs:", error);
      }
    };

    const fetchVehicleTypes = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/vehicle-types`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setVehicleTypes(response.data.vehicleType || []);
      } catch (error) {
        console.error("Error fetching vehicle types:", error);
      }
    };

    fetchDrivers();
    fetchHubs();
    fetchVehicleTypes();
  }, []);

  const handleClose = () => {
    set_update_data_modal_Show(false);
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (name === "latitude" || name === "longitude") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        coordinates: { ...prevData.coordinates, [name]: value },
      }));
    } else if (name === "id_driver") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        id_driver: { ...prevData.id_driver, [name]: value },
      }));
    } else if (name === "id_vendor") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        id_vendor: { ...prevData.id_vendor, [name]: value },
      }));
    } else if (name === "id_hub") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        id_hub: { ...prevData.id_hub, [name]: value },
      }));
    } else if (name === "id_type") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        id_type: { ...prevData.id_type, [name]: value },
      }));
    } else if (name === "length") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        length: value, // Update state with new length value
      }));
    } else if (name === "insuranceimage") {
      setUpdate_modal_data({ ...update_modal_data, [name]: files });
    } else if (name === "vehicleimage") {
      setUpdate_modal_data({ ...update_modal_data, [name]: files });
    } else {
      setUpdate_modal_data({ ...update_modal_data, [name]: value });
    }
  };

  const handleDriverChange = (selectedOptions) => {
    setUpdate_modal_data((prevData) => ({
      ...prevData,
      id_driver: selectedOptions,
    }));
  };

  const handleHubChange = (selectedOptions) => {
    setUpdate_modal_data((prevData) => ({
      ...prevData,
      id_hub: selectedOptions,
    }));
  };

  const handleVehicleTypeChange = (e) => {
    const { value } = e.target;
    setUpdate_modal_data((prevData) => ({
      ...prevData,
      id_type: value,
    }));
  };

  const onLoad = (autoComp) => {
    setAutocomplete(autoComp);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry && place.geometry.location) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        // Update coordinates in state
        setUpdate_modal_data((prevData) => ({
          ...prevData,
          assignedlocation: place.formatted_address,
          coordinates: { latitude: lat, longitude: lng },
        }));
      } else {
        console.log("Place details not found");
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "numberplate",
      "model",
      "year",
      "color",
      "coordinates",
      "status",
      "id_type",
      "assignedlocation",
      "lease",
      "length",
      "capacity",
    ];

    requiredFields.forEach((field) => {
      if (!update_modal_data[field] || update_modal_data[field].length === 0) {
        newErrors[field] = "This field is required";
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleUpdate = async () => {
    if (!validateForm()) {
      return;
    }
    const token = localStorage.getItem("token");
    if (!token) {
      Swal.fire("Unauthorized!");
      return;
    }
    try {
      const { _id } = update_modal_data;
      if (!_id) {
        throw new Error("ID is not defined");
      }

      const formData = new FormData();
      const idHubString = update_modal_data.id_hub
        .map((item) => item.value)
        .join(",");
      formData.append("id_hub", idHubString);

      // Append driver only if it's selected
      if (
        update_modal_data.id_driver &&
        update_modal_data.id_driver.length > 0
      ) {
        const idDriverString = update_modal_data.id_driver
          .map((item) => item.value)
          .join(",");
        formData.append("id_driver", idDriverString);
      }

      // Append vendor if selected
      if (update_modal_data.id_vendor) {
        formData.append("id_vendor", update_modal_data.id_vendor);
      }

      formData.append("numberplate", update_modal_data.numberplate);
      formData.append("model", update_modal_data.model);
      formData.append("color", update_modal_data.color);
      formData.append("assignedlocation", update_modal_data.assignedlocation);
      formData.append("lease", update_modal_data.lease);
      formData.append("length", update_modal_data.length);
      formData.append("capacity", update_modal_data.capacity);
      formData.append("status", update_modal_data.status);
      formData.append("id_type", update_modal_data.id_type);

      formData.append(
        "coordinates[latitude]",
        update_modal_data.coordinates?.latitude || ""
      );
      formData.append(
        "coordinates[longitude]",
        update_modal_data.coordinates?.longitude || ""
      );

      // Append files if they are present
      if (update_modal_data.vehicleimage instanceof FileList) {
        formData.append("vehicleimage", update_modal_data.vehicleimage[0]);
      }
      if (update_modal_data.insuranceimage instanceof FileList) {
        formData.append("insuranceimage", update_modal_data.insuranceimage[0]);
      }

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/v1/vehicle/${_id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        Swal.fire("Update success", "", "success");
        onUpdateVehicle(update_modal_data);
        setRen(!ren);
      } else {
        Swal.fire("Update failed", "", "error");
      }

      set_update_data_modal_Show(false);
    } catch (error) {
      Swal.fire("Update failed", "", "error");
      console.error("Error updating driver:", error);
    }
  };

  const handleVendorChange = (e) => {
    const { value } = e.target;
    setUpdate_modal_data((prevData) => ({
      ...prevData,
      id_vendor: value,
    }));
  };

  return (
    <>
      <Modal show={add_data_modal_Show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Vehicle Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Number Plate *</Form.Label>
              <Form.Control
                type="text"
                name="numberplate"
                value={update_modal_data.numberplate || ""}
                onChange={handleChange}
                isInvalid={!!errors.numberplate}
              />
              <Form.Control.Feedback type="invalid">
                {errors.numberplate}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Model *</Form.Label>
              <Form.Control
                type="text"
                name="model"
                value={update_modal_data.model || ""}
                onChange={handleChange}
                isInvalid={!!errors.model}
              />
              <Form.Control.Feedback type="invalid">
                {errors.model}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Year *</Form.Label>
              <Form.Control
                type="number"
                name="year"
                value={update_modal_data.year || ""}
                onChange={handleChange}
                isInvalid={!!errors.year}
              />
              <Form.Control.Feedback type="invalid">
                {errors.year}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Color *</Form.Label>
              <Form.Control
                type="text"
                name="color"
                value={update_modal_data.color || ""}
                onChange={handleChange}
                isInvalid={!!errors.color}
              />
              <Form.Control.Feedback type="invalid">
                {errors.color}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Longitude *</Form.Label>
              <Form.Control
                type="text"
                name="longitude"
                value={update_modal_data.coordinates?.longitude || ""}
                onChange={handleChange}
                isInvalid={!!errors.coordinates}
              />
              <Form.Control.Feedback type="invalid">
                {errors.coordinates}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Latitude *</Form.Label>
              <Form.Control
                type="text"
                name="latitude"
                value={update_modal_data.coordinates?.latitude || ""}
                onChange={handleChange}
                isInvalid={!!errors.coordinates}
              />
              <Form.Control.Feedback type="invalid">
                {errors.coordinates}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Location *</Form.Label>
              <LoadScript
                googleMapsApiKey={process.env.REACT_APP_BASE_API}
                libraries={["places"]}
              >
                <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                  <Form.Control
                    type="text"
                    placeholder="Search places..."
                    value={update_modal_data.assignedlocation || ""}
                    onChange={(e) =>
                      setUpdate_modal_data({
                        ...update_modal_data,
                        assignedlocation: e.target.value,
                      })
                    }
                    isInvalid={!!errors.assignedlocation}
                  />
                </Autocomplete>
              </LoadScript>
              <Form.Control.Feedback type="invalid">
                {errors.assignedlocation}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Assign Driver </Form.Label>
              <Select
                name="id_driver"
                value={update_modal_data.id_driver || []}
                options={drivers.map((driver) => ({
                  value: driver._id,
                  label: driver.id_user?.name,
                }))}
                isMulti
                onChange={handleDriverChange}
                className={`basic-multi-select ${
                  errors.id_driver ? "is-invalid" : ""
                }`}
                classNamePrefix="select"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Assign Hubs *</Form.Label>
              <Select
                name="id_hub"
                defaultValue={update_modal_data.id_hub || ""}
                options={hubs.map((hub) => ({
                  value: hub._id,
                  label: hub.name,
                }))}
                isMulti
                onChange={handleHubChange}
                className={`basic-multi-select ${
                  errors.id_hub ? "is-invalid" : ""
                }`}
                classNamePrefix="select"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Assign Vendor </Form.Label>
              <Form.Control
                as="select"
                name="id_vendor"
                value={update_modal_data.id_vendor || ""}
                onChange={handleVendorChange}
                isInvalid={!!errors.id_vendor}
              >
                <option value="">Select Vendor</option>
                {vendors.map((vendor) => (
                  <option key={vendor._id} value={vendor._id}>
                    {vendor.vendorname || vendor.id_user?.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Lease *</Form.Label>
              <Form.Control
                type="text"
                name="lease"
                value={update_modal_data.lease || ""}
                onChange={handleChange}
                isInvalid={!!errors.lease}
              />
              <Form.Control.Feedback type="invalid">
                {errors.lease}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Length *</Form.Label>
              <Form.Control
                type="text"
                name="length"
                value={update_modal_data.length || ""}
                onChange={handleChange}
                isInvalid={!!errors.length}
              />
              <Form.Control.Feedback type="invalid">
                {errors.length}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Capacity *</Form.Label>
              <Form.Control
                type="text"
                name="capacity"
                value={update_modal_data.capacity || ""}
                onChange={handleChange}
                isInvalid={!!errors.capacity}
              />
              <Form.Control.Feedback type="invalid">
                {errors.capacity}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Status *</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={update_modal_data.status || ""}
                onChange={handleChange}
                isInvalid={!!errors.status}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
                <option value="Unassigned">Unassigned</option>
                <option value="Service_Center">Service_Centre</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.status}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Vehicle Type</Form.Label>
              <Form.Control
                as="select"
                name="id_type"
                value={update_modal_data.id_type || ""}
                onChange={handleVehicleTypeChange}
                isInvalid={!!errors.id_type}
              >
                <option value="">Select Vehicle Type</option>
                {vehicleTypes.map((type) => (
                  <option key={type._id} value={type._id}>
                    {type.company}({type.vehiclename})
                  </option>
                ))}
              </Form.Control>
              {errors.id_type && (
                <Form.Text className="text-danger">{errors.id_type}</Form.Text>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Insurance Image </Form.Label>
              <Form.Control
                type="file"
                name="insuranceimage"
                onChange={handleChange}
                isInvalid={!!errors.insuranceimage}
              />
            </Form.Group>

            {(update_modal_data.insuranceimage &&
              update_modal_data.insuranceimage[0]) ||
            select_data.insuranceimage?.url ? (
              <Form.Group className="mb-3">
                <Form.Label>Insurance Image Preview</Form.Label>
                <div className="d-flex justify-content-center">
                  <img
                    src={
                      update_modal_data.insuranceimage &&
                      update_modal_data.insuranceimage[0]
                        ? URL.createObjectURL(
                            update_modal_data.insuranceimage[0]
                          )
                        : select_data.insuranceimage?.url
                    }
                    alt="Insurance Image"
                    style={{ maxWidth: "50%", height: "15%" }}
                  />
                </div>
              </Form.Group>
            ) : null}

            <Form.Group className="mb-3">
              <Form.Label>Vehicle Image </Form.Label>
              <Form.Control
                type="file"
                name="vehicleimage"
                onChange={handleChange}
                isInvalid={!!errors.vehicleimage}
              />
            </Form.Group>
            {(update_modal_data.vehicleimage &&
              update_modal_data.vehicleimage[0]) ||
            select_data.vehicleimage?.url ? (
              <Form.Group className="mb-3">
                <Form.Label>Vehicle Image Preview</Form.Label>
                <div className="d-flex justify-content-center">
                  <img
                    src={
                      update_modal_data.vehicleimage &&
                      update_modal_data.vehicleimage[0]
                        ? URL.createObjectURL(update_modal_data.vehicleimage[0])
                        : select_data.vehicleimage?.url
                    }
                    alt="Vehicle Image"
                    style={{ maxWidth: "50%", height: "15%" }}
                  />
                </div>
              </Form.Group>
            ) : null}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
