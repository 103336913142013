import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import Layouts from "../../layouts/layouts";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import Select from "react-select";

export default function Create_vehicle() {
  const title = "Add Vehicle";
  const [formData, setFormData] = useState({
    status: "Active",
    numberplate: "",
    model: "",
    year: "",
    insuranceimage: null,
    vehicleimage: null,
    color: "",
    coordinates: { latitude: "", longitude: "" },
    id_driver: [],
    id_hub: [],
    lease: "",
    length: "",
    capacity: "",
    comments: "",
    vehiclename: "",
    company: "",
    company_id: "",
    id_type: "",
    assignedlocation: "",
    id_vendor: "",
  });
  const [errors, setErrors] = useState({});
  const [drivers, setDrivers] = useState([]);
  const [hubs, setHubs] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [autocomplete, setAutocomplete] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [filteredVehicleTypes, setFilteredVehicleTypes] = useState([]);
  const [vendors, setVendors] = useState([]);

  const navigate = useNavigate();
  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];

  // Fetch drivers from the backend
  useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/drivers`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: ` Bearer ${token}`,
            },
          }
        );
        if (response.data && response.data.drivers) {
          setDrivers(response.data.drivers);
          console.log(response.data.drivers);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching drivers:", error);
      }
    };
    fetchDrivers();
  }, []);

  // Fetch hubs from the backend
  useEffect(() => {
    const fetchHubs = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          ` ${process.env.REACT_APP_BASE_URL}/api/v1/hubs`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data && response.data.hubs) {
          setHubs(response.data.hubs);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching hubs:", error);
        setHubs([]);
      }
    };
    fetchHubs();
  }, []);

  useEffect(() => {
    const fetchVehicleTypes = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/vehicle-types`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data && response.data.vehicleType) {
          setVehicleTypes(response.data.vehicleType);
          console.log(response.data.vehicleType);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching vehicle types:", error);
        setVehicleTypes([]);
      }
    };

    fetchVehicleTypes();
  }, []);

  useEffect(() => {
    if (formData.company) {
      const filtered = vehicleTypes.filter(
        (type) => type.company === formData.company
      );
      setFilteredVehicleTypes(filtered);
    } else {
      setFilteredVehicleTypes([]);
    }
  }, [formData.company, vehicleTypes]);

  const handleHubChange = (selectedOptions) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      id_hub: selectedOptions
        ? selectedOptions.map((option) => option.value)
        : [],
    }));
  };

  const onChangeHandler = (event) => {
    const { name, value, type, files } = event.target;

    if (type === "file") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: files[0],
      }));
    } else if (name === "latitude" || name === "longitude") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        coordinates: {
          ...prevFormData.coordinates,
          [name]: value,
        },
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/vendors`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setVendors(response.data.vendors);
        console.log("Vendors fetched:", response.data.vendors);
      } catch (error) {
        console.error("Error fetching vendor:", error);
        setVendors([]);
      }
    };
    fetchVendors();
  }, []);

  const handleDriverChange = (selectedOptions) => {
    const driverIds = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFormData((prevFormData) => ({
      ...prevFormData,
      id_driver: driverIds,
    }));
  };

  const handleVehicleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      company: selectedCompany,
      company_id: vehicleTypes.find((type) => type.company === selectedCompany)
        ?._id,
      id_type: "",
    }));
  };

  const handleVehicleTypeChange = (event) => {
    const selectedTypeId = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      vehiclename: filteredVehicleTypes.find(
        (type) => type._id === selectedTypeId
      )?.vehiclename,
      id_type: selectedTypeId,
    }));
  };

  const handleWheelTypeChange = (event) => {
    const selectedTypeId = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      vehicle_type: filteredVehicleTypes.find(
        (type) => type._id === selectedTypeId
      )?.vehicle_type,
      id_type: selectedTypeId,
    }));
  };

  const onLoad = (autoComp) => {
    setAutocomplete(autoComp);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setFormData((prevFormData) => ({
        ...prevFormData,
        assignedlocation: place.formatted_address,
        coordinates: { latitude: lat, longitude: lng },
      }));
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.numberplate) {
      newErrors.numberplate = "Number Plate is required.";
    }
    if (!formData.model) {
      newErrors.model = "Model is required.";
    }

    if (!formData.year) {
      newErrors.year = "Year is required.";
    } else if (!/^\d{4}$/.test(formData.year)) {
      newErrors.year = "Year must be a 4-digit number.";
    }
    if (!formData.insuranceimage) {
      newErrors.insuranceimage = "Insurance Image is required.";
    }

    if (!formData.vehicleimage) {
      newErrors.vehicleimage = "Vehicle Image is required.";
    }

    if (!formData.color) {
      newErrors.color = "Color is required.";
    }

    if (!formData.coordinates.latitude || !formData.coordinates.longitude) {
      newErrors.coordinates = "Coordinates are required.";
    }

    // if (formData.id_driver.length === 0) {
    //   newErrors.id_driver = "At least one driver must be assigned.";
    // }

    if (!formData.id_hub) {
      newErrors.id_hub = "Hub is required.";
    }

    if (!formData.vehiclename) {
      newErrors.vehiclename = "Vehicle Type is required.";
    }

    if (!formData.lease) {
      newErrors.lease = "Lease is required.";
    }

    if (!formData.length) {
      newErrors.length = "Length is required.";
    }

    if (!formData.capacity) {
      newErrors.capacity = "Capacity is required.";
    }

    if (!formData.status) {
      newErrors.status = "Status is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append("status", formData.status);
    formDataToSend.append("numberplate", formData.numberplate);
    formDataToSend.append("model", formData.model);
    formDataToSend.append("year", formData.year);
    formDataToSend.append("insuranceimage", formData.insuranceimage);
    formDataToSend.append("vehicleimage", formData.vehicleimage);
    formDataToSend.append("color", formData.color);
    if (formData.id_vendor) {
      formDataToSend.append("id_vendor", formData.id_vendor);
    }

    formDataToSend.append(
      "coordinates[latitude]",
      formData.coordinates.latitude
    );
    formDataToSend.append(
      "coordinates[longitude]",
      formData.coordinates.longitude
    );
    formDataToSend.append("id_driver", formData.id_driver);
    formDataToSend.append("id_hub", formData.id_hub);
    formDataToSend.append("lease", formData.lease);
    formDataToSend.append("length", formData.length);
    formDataToSend.append("capacity", formData.capacity);
    formDataToSend.append("comments", formData.comments);
    formDataToSend.append("vehiclename", formData.vehiclename);
    formDataToSend.append("vehicle_type", formData.vehicle_type);
    formDataToSend.append("company", formData.company);
    formDataToSend.append("company_id", formData.company_id);
    formDataToSend.append("id_type", formData.id_type);
    formDataToSend.append("assignedlocation", formData.assignedlocation);

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/vehicle/new`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        navigate("/vehicle");
      } else {
        console.error("Submission failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Layouts />
      <div className="container-fluid">
        <Breadcrumb title={title} brad={brad} />
        <form
          className="card"
          style={{ borderTop: "2px solid #4723d9" }}
          onSubmit={onSubmitHandler}
        >
          <div className="card-header d-flex justify-content-between border-bottom pb-1">
            <div className="">{title} </div>
            <div className="btn btn-info btn-sm" onClick={() => navigate(-1)}>
              Back
            </div>
          </div>
          <div className="card-body">
            <div className="row justify-content-center">
              <div className="col-md-6 mt-3">
                <label htmlFor="numberplate">
                  <b>Number Plate *</b>
                </label>
                <input
                  type="text"
                  name="numberplate"
                  placeholder="Number-Plate"
                  className={`form-control ${
                    errors.numberplate ? "is-invalid" : ""
                  }`}
                  onChange={onChangeHandler}
                  value={formData.numberplate}
                />
                {errors.numberplate && (
                  <div className="invalid-feedback">{errors.numberplate}</div>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="model">
                  <b>Model *</b>
                </label>
                <input
                  type="text"
                  name="model"
                  placeholder="Model"
                  className={`form-control ${errors.model ? "is-invalid" : ""}`}
                  value={formData.model}
                  onChange={onChangeHandler}
                />
                {errors.model && (
                  <div className="invalid-feedback">{errors.model}</div>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="year">
                  <b>Year *</b>
                </label>
                <input
                  type="number"
                  name="year"
                  placeholder="Year"
                  className={`form-control ${errors.year ? "is-invalid" : ""}`}
                  value={formData.year}
                  onChange={onChangeHandler}
                />
                {errors.year && (
                  <div className="invalid-feedback">{errors.year}</div>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="insuranceimage">
                  <b>Insurance Image *</b>
                </label>
                <input
                  type="file"
                  name="insuranceimage"
                  className={`form-control ${
                    errors.insuranceimage ? "is-invalid" : ""
                  }`}
                  onChange={onChangeHandler}
                />
                {errors.insuranceimage && (
                  <div className="invalid-feedback">
                    {errors.insuranceimage}
                  </div>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="vehicleimage">
                  <b>Vehicle Image *</b>
                </label>
                <input
                  type="file"
                  name="vehicleimage"
                  className={`form-control ${
                    errors.vehicleimage ? "is-invalid" : ""
                  }`}
                  onChange={onChangeHandler}
                />
                {errors.vehicleimage && (
                  <div className="invalid-feedback">{errors.vehicleimage}</div>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="color">
                  <b>Color *</b>
                </label>
                <input
                  type="text"
                  name="color"
                  placeholder="Color"
                  className={`form-control ${errors.color ? "is-invalid" : ""}`}
                  value={formData.color}
                  onChange={onChangeHandler}
                />
                {errors.color && (
                  <div className="invalid-feedback">{errors.color}</div>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="coordinates">
                  <b>Coordinates *</b>
                </label>
                <input
                  type="text"
                  name="coordinates"
                  placeholder="Latitude, Longitude"
                  className="form-control"
                  value={`${formData.coordinates.latitude}, ${formData.coordinates.longitude}`}
                  readOnly
                />
                {errors.coordinates && (
                  <p className="text-danger">{errors.coordinates}</p>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="assignedlocation">
                  <b>Actual Location *</b>
                </label>
                <LoadScript
                  googleMapsApiKey={process.env.REACT_APP_BASE_API}
                  libraries={["places"]}
                >
                  <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                    <input
                      type="text"
                      placeholder="Search places..."
                      className="form-control"
                    />
                  </Autocomplete>
                </LoadScript>
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="id_driver">
                  <b>Assign Driver </b>
                </label>
                <Select
                  name="id_driver"
                  options={drivers.map((driver) => ({
                    value: driver._id,
                    label: `${driver.id_user?.name} (${driver.id_user?.phonenumber})`,
                  }))}
                  isMulti
                  onChange={handleDriverChange}
                  className={`basic-multi-select ${
                    errors.id_driver ? "is-invalid" : ""
                  }`}
                  classNamePrefix="select"
                />
                {/* {errors.id_driver && (
                  <div className="invalid-feedback">{errors.id_driver}</div>
                )} */}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="id_hub">
                  <b>Assign Hub *</b>
                </label>
                <Select
                  name="id_hub"
                  options={hubs.map((hub) => ({
                    value: hub._id,
                    label: `${hub.name}`,
                  }))}
                  isMulti
                  onChange={handleHubChange}
                  className={`basic-multi-select ${
                    errors.id_hub ? "is-invalid" : ""
                  }`}
                  classNamePrefix="select"
                />
                {errors.id_hub && (
                  <div className="invalid-feedback">{errors.id_hub}</div>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="company">
                  <b>Vehicle Company *</b>
                </label>
                <select
                  name="company"
                  className={`form-control ${
                    errors.company ? "is-invalid" : ""
                  }`}
                  onChange={handleVehicleCompanyChange}
                  value={formData.company}
                >
                  <option value="">Select Vehicle Company</option>
                  {Array.isArray(vehicleTypes) &&
                    [...new Set(vehicleTypes.map((type) => type.company))].map(
                      (company) => (
                        <option key={company} value={company}>
                          {company}
                        </option>
                      )
                    )}
                </select>
                {errors.company && (
                  <div className="invalid-feedback">{errors.company}</div>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="vehiclename">
                  <b>Vehicle Type </b>
                </label>
                <select
                  name="vehiclename"
                  className={`form-control ${
                    errors.vehiclename ? "is-invalid" : ""
                  }`}
                  onChange={handleVehicleTypeChange}
                  value={formData.id_type}
                >
                  <option value="">Select Vehicle Type</option>
                  {Array.isArray(filteredVehicleTypes) &&
                    filteredVehicleTypes.map((type) => (
                      <option key={type._id} value={type._id}>
                        {type.vehiclename}
                      </option>
                    ))}
                </select>
                {errors.vehiclename && (
                  <div className="invalid-feedback">{errors.vehiclename}</div>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="totalkm">
                  <b>Total Km *</b>
                </label>
                <input
                  type="number"
                  name="totalkm"
                  placeholder="Total Km"
                  className={`form-control ${
                    errors.totalkm ? "is-invalid" : ""
                  }`}
                  value={formData.totalkm}
                  onChange={onChangeHandler}
                />
                {errors.totalkm && (
                  <div className="invalid-feedback">{errors.totalkm}</div>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="lease">
                  <b>Lease *</b>
                </label>
                <input
                  type="text"
                  name="lease"
                  placeholder="Lease"
                  className={`form-control ${errors.lease ? "is-invalid" : ""}`}
                  value={formData.lease}
                  onChange={onChangeHandler}
                />
                {errors.lease && (
                  <div className="invalid-feedback">{errors.lease}</div>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="length">
                  <b>Length *</b>
                </label>
                <input
                  type="number"
                  name="length"
                  placeholder="Length"
                  className={`form-control ${
                    errors.length ? "is-invalid" : ""
                  }`}
                  value={formData.length}
                  onChange={onChangeHandler}
                />
                {errors.length && (
                  <div className="invalid-feedback">{errors.length}</div>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="capacity">
                  <b>Capacity *</b>
                </label>
                <input
                  type="number"
                  name="capacity"
                  placeholder="Capacity"
                  className={`form-control ${
                    errors.capacity ? "is-invalid" : ""
                  }`}
                  value={formData.capacity}
                  onChange={onChangeHandler}
                />
                {errors.capacity && (
                  <div className="invalid-feedback">{errors.capacity}</div>
                )}
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="vendor">
                  <b>Vendor </b>
                </label>
                <select
                  name="id_vendor"
                  className="form-control"
                  value={formData.id_vendor || ""}
                  onChange={onChangeHandler}
                >
                  <option value="">Select Vendor</option>
                  {Array.isArray(vendors) &&
                    vendors.map((vendor) => (
                      <option key={vendor._id} value={vendor._id}>
                        {vendor?.id_user?.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="status">
                  <b>Vehicle Status *</b>
                </label>
                <select
                  name="status"
                  className={`form-control ${
                    errors.status ? "is-invalid" : ""
                  }`}
                  value={formData.status}
                  onChange={onChangeHandler}
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                  <option value="Unassigned">Unassigned</option>
                  <option value="Service_Center">Service_Center</option>
                </select>
                {errors.status && (
                  <div className="invalid-feedback">{errors.status}</div>
                )}
              </div>
              <div className="col-md-6 mt-3"></div>
              <div className="col-12 mt-3">
                <label htmlFor="comments">
                  <b>Comments</b>
                </label>
                <textarea
                  name="comments"
                  type="text"
                  placeholder="Comments"
                  rows="5"
                  className="form-control"
                  value={formData.comments}
                  onChange={onChangeHandler}
                ></textarea>
              </div>
            </div>
            <button type="submit" className="btn btn-primary btn-sm mt-3">
              Add Vehicle
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
