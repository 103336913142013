import { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";

export default function Add_data_modal({
  select_data,
  open_update_data_modal,
  onUpdateType,
}) {
  const [ren, setRen] = useState(false);
  const [update_modal_data, setUpdate_modal_data] = useState(select_data);
  const [add_data_modal_Show, set_update_data_modal_Show] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (select_data) {
      setUpdate_modal_data(select_data);
      setUpdate_modal_data((prevData) => ({
        ...prevData,
      }));
    }
  }, [select_data]);

  useEffect(() => {
    set_update_data_modal_Show(open_update_data_modal.modal_open);
  }, [open_update_data_modal]);

  const handleClose = () => {
    set_update_data_modal_Show(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "id_client") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        id_client: { ...prevData.id_client, [name]: value },
      }));
    } else {
      setUpdate_modal_data({ ...update_modal_data, [name]: value });
    }
  };

  const validateFields = () => {
    const newErrors = {};
    let isValid = true;
    if (!update_modal_data.company) {
      errors.company = "Company is required";
      isValid = false;
    }
    if (!update_modal_data.vehiclename) {
      errors.vehiclename = "Vehicle Type is required";
      isValid = false;
    }
    if (!update_modal_data.vehicle_type) {
      newErrors.vehicle_type = "Wheeler Type Selection is required";
      isValid = false;
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleUpdate = async () => {
    const token = localStorage.getItem("token");

    if (!validateFields()) {
      Swal.fire("Please fill in all required fields", "", "error");
      return;
    }

    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to take this action?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { _id } = update_modal_data;
          if (!_id) {
            throw new Error("ID is not defined");
          }

          const formData = new FormData();
          formData.append("company", update_modal_data.company);
          formData.append("vehiclename", update_modal_data.vehiclename);
          formData.append("vehicle_type", update_modal_data.vehicle_type);

          const res = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/v1/vehicle-types/${_id}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (res.data.success) {
            Swal.fire("Update success", "", "success");
            onUpdateType(update_modal_data);
            setRen(!ren);
          }
        } catch (error) {
          Swal.fire("Update failed", "", "error");
          console.error("Error updating type:", error);
        }
        set_update_data_modal_Show(false);
      }
    });
  };

  return (
    <>
      <Modal show={add_data_modal_Show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Type Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label> Company *</Form.Label>
              <Form.Control
                type="text"
                name="company"
                value={update_modal_data.company || ""}
                onChange={handleChange}
                placeholder="Enter Company"
              />
              {errors.company && (
                <p className="text-danger">{errors.company}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Vehicle Type *</Form.Label>
              <Form.Control
                type="text"
                name="vehiclename"
                value={update_modal_data.vehiclename || ""}
                onChange={handleChange}
                placeholder="Enter Name"
              />
              {errors.vehiclename && (
                <p className="text-danger">{errors.vehiclename}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Wheeler Type *</Form.Label>
              <Form.Select
                name="vehicle_type"
                value={update_modal_data.vehicle_type || ""}
                onChange={handleChange}
              >
                <option value="">Select Wheeler Type</option>
                <option value="2W">2W</option>
                <option value="3W">3W</option>
                <option value="4W">4W</option>
              </Form.Select>
              {errors.vehicle_type && (
                <p className="text-danger">{errors.vehicle_type}</p>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-sm"
            onClick={handleUpdate}
          >
            Update Type
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
