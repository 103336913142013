import { useState, useEffect } from "react";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import DataTable_Component from "../../components/ordersuserreport/Data Table Component/index";
import Status_modal from "../../components/manageInvoice/status_modal/index";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import {
  faEye,
  faPenToSquare,
  faTrash,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

import Layouts from "../../layouts/layouts";
import { Tooltip } from "react-tooltip";
import { Line } from "react-chartjs-2"; // Import Line from Chart.js
import { Chart as ChartJS, registerables } from "chart.js"; // Import and register Chart.js

ChartJS.register(...registerables); // Register required components for Chart.js

export default function OrderUserReport() {
  const title = "Order User Report";
  const [apistate, setApiState] = useState([]);
  const [apicol, setApiCol] = useState([]);
  const [selectVal, setSelectval] = useState([]);
  const [openModal, setOpenModal] = useState(true);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isFiltered, setIsFiltered] = useState(false); // Track if filters are applied

  const [open_add_modal, setOpen_add_modal] = useState({
    render: true,
    modal_open: false,
  });

  const [open_update_modal, setOpen_update_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [update_modal_data, setUpdate_modal_data] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [showHubDetailModal, setShowHubDetailModal] = useState(false);
  const [selectedHub, setSelectedHub] = useState(null);

  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const columns = [
    {
      name: <b>User Name</b>,
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: <b> Total Orders</b>,
      selector: (row) => row.totalOrders,
      sortable: true,
    },
    {
      name: <b> Delivered Orders</b>,
      selector: (row) => row.orderDeliver,
      sortable: true,
    },
    {
      name: <b>Individual Km</b>,
      selector: (row) => row.totalKm,
      sortable: true,
    },
    {
      name: <b>Individual Amount</b>,
      selector: (row) => row.totalAmount,
      sortable: true,
    },
    {
      name: <b>Hub Name</b>,
      cell: (row) => {
        const tripSummaryValues = row.tripSummary
          ? Object.values(row.tripSummary)
          : [];
        const uniqueHubs = Array.from(
          new Set(tripSummaryValues.map((trip) => trip.hub))
        );

        return (
          <div style={{ position: "relative", zIndex: 1 }}>
            {uniqueHubs.length > 0 ? (
              <>
                <span>{uniqueHubs.join(", ").slice(0, 20)}...</span>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  data-tooltip-id={`tooltip-hub-name-${row.userId}`}
                  data-tooltip-content={uniqueHubs.join(", ")}
                  style={{ cursor: "pointer", marginLeft: "5px" }}
                />
                <Tooltip
                  id={`tooltip-hub-name-${row.userId}`}
                  place="bottom"
                  style={{ zIndex: 9999, backgroundColor: "black" }}
                />
              </>
            ) : (
              "-"
            )}
          </div>
        );
      },
      sortable: true,
    },
  ];

  const fetchData = async (filterFromDate = "", filterToDate = "") => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }

      let url = `${process.env.REACT_APP_BASE_URL}/api/v1/orders/user-summary/consolidation`;

      const params = {};
      if (filterFromDate && filterToDate) {
        params.dateFrom = filterFromDate;
        params.dateTo = filterToDate;
        console.log("Params.datefrom", params.dateFrom);
        console.log("Params.dateTo", params.dateTo);
      } else {
        // Default: fetch data for the previous month
        const today = new Date();
        const firstDayOfPrevMonth = moment(today)
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        const lastDayOfPrevMonth = moment(today)
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
        params.dateFrom = firstDayOfPrevMonth;
        params.dateTo = lastDayOfPrevMonth;
      }

      const response = await axios.get(url, {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      // Access the correct part of the response
      const { orderSummary } = response.data;
      if (orderSummary && Array.isArray(orderSummary.userSummary)) {
        const userData = orderSummary.userSummary.map((user) => ({
          ...user,
          totalTrips: user.trips || 0, // Correct key: 'trips'
          totalKm: user.kmDriven || 0, // Correct key: 'kmDriven'
          totalAmount: user.amount || 0, // Correct key: 'amount'
        }));

        setApiState(userData);
        console.log("Response", response.data);
      } else {
        console.error("Unexpected data format: ", orderSummary);
      }

      setApiCol(columns);
    } catch (error) {
      if (error.response) {
        console.error("API Error Response:", error.response.data);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Request setup error:", error.message);
      }
    }
  };

  const chartData = {
    labels: apistate.map((user) => user.name),
    datasets: [
      {
        label: "Orders Per User",
        data: apistate.map((user) => user.orderDeliver || 0),
        fill: false,
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.4)",
        tension: 0.4,
        pointBackgroundColor: "rgba(75, 192, 192, 1)",
        pointBorderColor: "#fff",
        pointRadius: 5,
      },
    ],
  };

  const handleFilterApply = (filterFromDate, filterToDate) => {
    setIsFiltered(true); // Mark that filters are applied
    setFromDate(filterFromDate);
    setToDate(filterToDate);
    fetchData(filterFromDate, filterToDate); // Fetch filtered data
  };

  const handleRemoveFilters = () => {
    setFromDate("");
    setToDate("");
    fetchData();
  };

  useEffect(() => {
    if (!isFiltered) {
      fetchData();
    }
  }, [isFiltered]);
  const setStoreBtn = () => {
    setOpen_add_modal({ render: !open_add_modal.render, modal_open: true });
  };

  const setUpdateStoreBtn = (row) => {
    setOpen_update_modal({
      render: !open_update_modal.render,
      modal_open: true,
    });
    setUpdate_modal_data(row);
  };

  return (
    <>
      <Layouts />
      <Status_modal row={selectVal} openModal={openModal} />
      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between border-bottom pb-1">
                <h4>{title}</h4>
              </div>
              <div className="card-body">
                <div
                  className="my-4"
                  style={{ height: "500px", width: "100%" }}
                >
                  {" "}
                  {apistate.length > 0 ? (
                    <Line
                      data={chartData}
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                          x: {
                            ticks: {
                              font: {
                                size: 12,
                              },
                            },
                          },
                          y: {
                            ticks: {
                              font: {
                                size: 12,
                              },
                            },
                          },
                        },
                      }}
                    />
                  ) : (
                    <p>Loading...</p>
                  )}
                </div>
                {apistate.length > 0 ? (
                  <DataTable_Component
                    searchBy="name"
                    title_btn={true}
                    title="User List"
                    apidata={apistate}
                    columns={apicol}
                    onFilterApply={handleFilterApply}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
