import React from "react";
import { Modal, Button } from "react-bootstrap";

const OrderDetailModal = ({ show, onHide, order }) => {
  if (!order) return null;

  // Function to format date
  const formatDate = (date) => {
    if (!date) return "N/A";
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Order Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>Name:</strong> {order.id_user ? order.id_user.name : "N/A"}
        </p>
        <p>
          <strong>Number Plate :</strong> {order.id_vehicle?.numberplate}
        </p>
        <p>
          <strong>Total Orders:</strong> {order.dailyOrder}
        </p>
        <p>
          <strong>Daily Delivered:</strong> {order.dailyDeliver}
        </p>
        <p>
          <strong>Daily UnDelivered:</strong> {order.dailyUndelivered}
        </p>
        <p>
          <strong>Order Picked :</strong> {order.orderPicked}
        </p>
        <p>
          <strong>Order Un-Picked :</strong> {order.unPickedOrder}
        </p>
        <p>
          <strong>Cash Short :</strong> {order.cashSort}
        </p>
        <p>
          <strong>KG :</strong> {order.kg}
        </p>
        <p>
          <strong>Amount :</strong> {order.amount}
        </p>
        <p>
          <strong>Km Driven :</strong> {order.kmDriven}
        </p>
        <p>
          <strong>Vehicle Type :</strong>{" "}
          {order.id_vehicle?.id_type?.vehicle_type}
        </p>
        <p>
          <strong>Shift :</strong> {order.shift}
        </p>
        <p>
          <strong>Client:</strong>{" "}
          {order.id_client ? order.id_client.name : "N/A"}
        </p>
        <p>
          <strong>Date:</strong> {formatDate(order.date)}
        </p>
        <p>
          <strong>Comments:</strong> {order.comment}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OrderDetailModal;
