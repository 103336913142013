import { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";

export default function Add_data_modal({
  select_data,
  open_update_data_modal,
  onUpdateOrder,
}) {
  const [ren, setRen] = useState(false);
  const [update_modal_data, setUpdate_modal_data] = useState(select_data);
  const [add_data_modal_Show, set_update_data_modal_Show] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [errors, setErrors] = useState({});
  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    if (select_data) {
      setUpdate_modal_data(select_data);
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        id_client: select_data.id_client._id,
        id_vehicle: select_data.id_vehicle ? select_data.id_vehicle._id : "",
        id_user: select_data.id_user._id,
        date: select_data.date._id || "",
      }));
    }
  }, [select_data, clients, users, vehicles]);

  useEffect(() => {
    set_update_data_modal_Show(open_update_data_modal.modal_open);
  }, [open_update_data_modal]);

  const handleClose = () => {
    set_update_data_modal_Show(false);
  };
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/admin/users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUsers(response.data.users || []);
      } catch (error) {
        console.error("Error fetching users:", error);
        setUsers([]);
      }
    };

    const fetchVehicles = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/vehicles`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setVehicles(response.data.vehicles || []);
      } catch (error) {
        console.error("Error fetching vehicles:", error);
        setVehicles([]);
      }
    };

    const fetchClients = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/clients`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setClients(response.data.clients || []);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };
    fetchUsers();
    fetchClients();
    fetchVehicles();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "id_client") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        id_client: { ...prevData.id_client, [name]: value },
      }));
    }
    if (name === "id_vehicle") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        id_vehicle: { ...prevData.id_vehicle, [name]: value },
      }));
    } else if (name === "date") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        date: value,
      }));
    } else if (name === "id_user") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        id_user: { ...prevData.id_user, [name]: value },
      }));
    } else {
      setUpdate_modal_data({ ...update_modal_data, [name]: value });
    }
  };

  const handleUserChange = (e) => {
    const { value } = e.target;
    setUpdate_modal_data((prevData) => ({
      ...prevData,
      id_user: value,
    }));
  };

  const handleClientChange = (e) => {
    const { value } = e.target;
    setUpdate_modal_data((prevData) => ({
      ...prevData,
      id_client: value,
    }));
  };

  const handleVehicleChange = (e) => {
    const { value } = e.target;
    setUpdate_modal_data((prevData) => ({
      ...prevData,
      id_vehicle: value,
    }));
  };

  const validateFields = () => {
    const newErrors = {};
    if (!update_modal_data.id_user) newErrors.id_user = "User ID is required";
    if (!update_modal_data.dailyOrder)
      newErrors.dailyOrder = "Daily Order is required";
    if (!update_modal_data.dailyDeliver)
      newErrors.dailyDeliver = "Daily  Delivered is required";
    if (!update_modal_data.id_client)
      newErrors.id_client = "Client is required";
    if (!update_modal_data.vehicle) {
      errors.vehicle = "Vehicle is required";
    }
    if (!update_modal_data.date) {
      errors.date = "Date is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleUpdate = async () => {
    const token = localStorage.getItem("token");

    if (!validateFields()) {
      Swal.fire("Please fill in all required fields", "", "error");
      return;
    }

    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to take this action?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { _id } = update_modal_data;
          if (!_id) {
            throw new Error("ID is not defined");
          }

          // Create a new FormData object
          const formData = new FormData();
          formData.append("id_user", update_modal_data.id_user);
          formData.append("dailyOrder", update_modal_data.dailyOrder);
          formData.append("dailyDeliver", update_modal_data.dailyDeliver);
          formData.append("date", update_modal_data.date);
          formData.append("orderPicked", update_modal_data.orderPicked);
          formData.append("unPickedOrder", update_modal_data.unPickedOrder);
          formData.append("cashSort", update_modal_data.cashSort);
          formData.append("kg", update_modal_data.kg ?? 0);

          formData.append("shift", update_modal_data.shift);
          formData.append("amount", update_modal_data.amount ?? 0);
          formData.append("kmDriven", update_modal_data.kmDriven ?? 0);
          formData.append("comment", update_modal_data.comment);
          formData.append("id_client", update_modal_data.id_client);
          formData.append("id_vehicle", update_modal_data.id_vehicle);
          const res = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/v1/order/${_id}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (res.data.success) {
            Swal.fire("Update success", "", "success");
            onUpdateOrder(update_modal_data);
            setRen(!ren);
          }
        } catch (error) {
          Swal.fire("Update failed", "", "error");
          console.error("Error updating order:", error);
        }
        set_update_data_modal_Show(false);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  return (
    <>
      <Modal show={add_data_modal_Show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Order Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="id_user">
              <Form.Label>User *</Form.Label>
              <Form.Control
                as="select"
                name="id_user"
                value={update_modal_data.id_user || ""}
                onChange={handleUserChange}
                isInvalid={!!errors.id_user}
              >
                <option value="">Select User</option>
                {users.map((user) => (
                  <option key={user._id} value={user._id}>
                    {/* {user.name} */}
                    {user.name || "Unknown Name"} (
                    {user.phonenumber || "Unknown Phone"} )
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.id_user}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="id_client">
              <Form.Label>Vehicle *</Form.Label>
              <Form.Control
                as="select"
                name="id_vehicle"
                value={update_modal_data.id_vehicle || ""}
                onChange={handleVehicleChange}
                isInvalid={!!errors.id_vehicle}
              >
                <option value="">Select Vehicle</option>
                {vehicles.map((vehicle) => (
                  <option key={vehicle._id} value={vehicle._id}>
                    {vehicle.numberplate}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.id_vehicle}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="joiningDate">
              <Form.Label>Date *</Form.Label>
              <Form.Control
                type="date"
                name="date"
                value={update_modal_data.date || ""}
                onChange={handleChange}
                isInvalid={!!errors.date}
              />
              <Form.Control.Feedback type="invalid">
                {errors.date}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Total Order *</Form.Label>
              <Form.Control
                type="number"
                name="dailyOrder"
                value={update_modal_data.dailyOrder || ""}
                onChange={handleChange}
                isInvalid={!!errors.dailyOrder}
              />
              <Form.Control.Feedback type="invalid">
                {errors.dailyOrder}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Daily Delivered *</Form.Label>
              <Form.Control
                type="number"
                name="dailyDeliver"
                value={update_modal_data.dailyDeliver || ""}
                onChange={handleChange}
                isInvalid={!!errors.dailyDeliver}
              />
              <Form.Control.Feedback type="invalid">
                {errors.dailyDeliver}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Order Picked *</Form.Label>
              <Form.Control
                type="number"
                name="orderPicked"
                value={update_modal_data.orderPicked || ""}
                onChange={handleChange}
                isInvalid={!!errors.orderPicked}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Order Un-Picked *</Form.Label>
              <Form.Control
                type="number"
                name="unPickedOrder"
                value={update_modal_data.unPickedOrder || ""}
                onChange={handleChange}
                isInvalid={!!errors.unPickedOrder}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Cash Short *</Form.Label>
              <Form.Control
                type="number"
                name="cashSort"
                value={update_modal_data.cashSort || ""}
                onChange={handleChange}
                isInvalid={!!errors.cashSort}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>KG </Form.Label>
              <Form.Control
                type="number"
                name="kg"
                value={update_modal_data.kg || ""}
                onChange={handleChange}
                isInvalid={!!errors.kg}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                name="amount"
                value={update_modal_data.amount || ""}
                onChange={handleChange}
                isInvalid={!!errors.amount}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Km Driven </Form.Label>
              <Form.Control
                type="number"
                name="kmDriven"
                value={update_modal_data.kmDriven || ""}
                onChange={handleChange}
                isInvalid={!!errors.kmDriven}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="id_client">
              <Form.Label>Client *</Form.Label>
              <Form.Control
                as="select"
                name="id_client"
                value={update_modal_data.id_client || ""}
                onChange={handleClientChange}
                isInvalid={!!errors.id_client}
              >
                <option value="">Select Client</option>
                {clients.map((client) => (
                  <option key={client._id} value={client._id}>
                    {client.name} ({client.id_hub?.name})
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.id_client}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Shift</Form.Label>
              <Form.Control
                as="select"
                name="shift"
                value={update_modal_data.shift || ""}
                onChange={handleChange}
                isInvalid={!!errors.shift}
              >
                <option value="">Select Shift</option>
                <option value="Morning">Morning</option>
                <option value="Afternoon">Afternoon</option>
                <option value="Evening">Evening</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.shift}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Comments </Form.Label>
              <Form.Control
                type="text"
                name="comment"
                value={update_modal_data.comment || ""}
                onChange={handleChange}
                isInvalid={!!errors.comment}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-sm"
            onClick={handleUpdate}
          >
            Update Order
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
