import { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import { Country, State, City } from "country-state-city";
import { LoadScript, Autocomplete } from "@react-google-maps/api";

export default function Add_data_modal({
  select_data,
  open_update_data_modal,
  onUpdateDriver,
}) {
  const [ren, setRen] = useState(false);
  const [update_modal_data, setUpdate_modal_data] = useState(select_data);
  const [add_data_modal_Show, set_update_data_modal_Show] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [users, setUsers] = useState([]);
  const [errors, setErrors] = useState({}); // State to hold validation errors
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [selectedStateCode, setSelectedStateCode] = useState("");
  const [autocomplete, setAutocomplete] = useState(null);

  useEffect(() => {
    if (select_data) {
      setUpdate_modal_data(select_data);
      setSelectedCountryCode(select_data.country || "");
      setSelectedStateCode(select_data.state || "");
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        id_vendor: select_data.id_vendor?._id,

        id_user: select_data.id_user._id,
        joiningDate: select_data.joiningDate || "",
      }));
    }
  }, [select_data, vendors, users]);

  useEffect(() => {
    set_update_data_modal_Show(open_update_data_modal.modal_open);
  }, [open_update_data_modal]);

  useEffect(() => {
    const fetchVendors = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/vendors`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setVendors(response.data.vendors || []);
      } catch (error) {
        console.error("Error fetching vendor:", error);
        setVendors([]);
      }
    };
    fetchVendors();
  }, []);

  useEffect(() => {
    const fetchCountries = async () => {
      const countryData = Country.getAllCountries().map((country) => ({
        name: country.name,
        code: country.isoCode,
      }));
      setCountries(countryData);
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    if (selectedCountryCode) {
      const stateData = State.getStatesOfCountry(selectedCountryCode).map(
        (state) => ({
          name: state.name,
          code: state.isoCode,
        })
      );
      setStates(stateData);
      setCities([]); // Clear cities when country changes
    }
  }, [selectedCountryCode]);

  useEffect(() => {
    if (selectedStateCode && selectedCountryCode) {
      const cityData = City.getCitiesOfState(
        selectedCountryCode,
        selectedStateCode
      ).map((city) => ({
        name: city.name,
        code: city.name,
      }));
      setCities(cityData);
    }
  }, [selectedStateCode, selectedCountryCode]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/admin/users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUsers(response.data.users || []);
      } catch (error) {
        console.error("Error fetching users:", error);
        setUsers([]);
      }
    };
    fetchUsers();
  }, []);

  const handleClose = () => {
    set_update_data_modal_Show(false);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "latitude" || name === "longitude") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        coordinates: { ...prevData.coordinates, [name]: value },
      }));
    } else if (name === "joiningDate") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        joiningDate: value,
      }));
    } else if (name === "id_vendor") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        id_vendor: { ...prevData.id_vendor, [name]: value },
      }));
    } else if (name === "id_user") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        id_user: { ...prevData.id_user, [name]: value },
      }));
    } else if (name === "driverlicense") {
      setUpdate_modal_data({ ...update_modal_data, [name]: files });
    } else if (name === "governmentidentification") {
      setUpdate_modal_data({ ...update_modal_data, [name]: files });
    } else {
      setUpdate_modal_data({ ...update_modal_data, [name]: value });
    }
    if (name === "country") {
      setSelectedCountryCode(value);
    }
    if (name === "state") {
      setSelectedStateCode(value);
    }
  };

  const handleUserChange = (e) => {
    const { value } = e.target;
    setUpdate_modal_data((prevData) => ({
      ...prevData,
      id_user: value,
    }));
  };

  const validateForm = () => {
    const errors = {};
    // Validate required fields
    if (!update_modal_data.homephonenumber) {
      errors.homephonenumber = "Home Number is required";
    }
    if (!update_modal_data.joiningDate) {
      errors.joiningDate = "Joining Date is required";
    }
    if (!update_modal_data.tempaddress) {
      errors.tempaddress = "Temporary Address is required";
    }
    if (!update_modal_data.country) {
      errors.country = "Country is required";
    }
    if (!update_modal_data.state) {
      errors.state = "State is required";
    }
    if (!update_modal_data.city) {
      errors.city = "City is required";
    }
    if (!update_modal_data.permanentaddress) {
      errors.permanentaddress = "Permanent Address is required";
    }
    if (!update_modal_data.driverlicense) {
      errors.driverlicense = "Driver License Image is required";
    }
    if (!update_modal_data.id_user) {
      errors.id_user = "User is required";
    }
    if (!update_modal_data.governmentidentification) {
      errors.governmentidentification = "Government Identification is required";
    }
    // if (!update_modal_data.id_vendor) {
    //   errors.id_vendor = "Vendor is required";
    // }
    if (!update_modal_data.status) {
      errors.status = "Status is required";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0; // Form is valid if errors object is empty
  };

  const handleUpdate = async () => {
    if (!validateForm()) {
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      Swal.fire("Unauthorized!");
      return;
    }

    try {
      const { _id } = update_modal_data;
      if (!_id) {
        throw new Error("ID is not defined");
      }

      const formData = new FormData();
      formData.append("homephonenumber", update_modal_data.homephonenumber);
      formData.append("joiningDate", update_modal_data.joiningDate);
      formData.append("tempaddress", update_modal_data.tempaddress);
      formData.append("country", update_modal_data.country);
      formData.append("state", update_modal_data.state);
      formData.append("city", update_modal_data.city);
      formData.append("permanentaddress", update_modal_data.permanentaddress);
      formData.append("id_user", update_modal_data.id_user);
      formData.append("id_vendor", update_modal_data.id_vendor);
      formData.append("status", update_modal_data.status);
      formData.append("location", update_modal_data.location);
      formData.append(
        "coordinates[latitude]",
        update_modal_data.coordinates?.latitude || ""
      );
      formData.append(
        "coordinates[longitude]",
        update_modal_data.coordinates?.longitude || ""
      );

      if (update_modal_data.driverlicense instanceof FileList) {
        formData.append("driverlicense", update_modal_data.driverlicense[0]);
      }
      if (update_modal_data.governmentidentification instanceof FileList) {
        formData.append(
          "governmentidentification",
          update_modal_data.governmentidentification[0]
        );
      }

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/v1/driver/${_id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        Swal.fire("Update success", "", "success");
        onUpdateDriver(update_modal_data); // Update locally
        setRen(!ren); // Trigger rerender if necessary
      } else {
        Swal.fire("Update failed", "", "error");
      }

      set_update_data_modal_Show(false); // Close modal on success or failure
    } catch (error) {
      Swal.fire("Update failed", "", "error");
      console.error("Error updating driver:", error);
    }
  };

  const onLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry && place.geometry.location) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        setUpdate_modal_data((prevData) => ({
          ...prevData,
          coordinates: { latitude: lat, longitude: lng },
        }));
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const handleVendorChange = (e) => {
    const { value } = e.target;
    setUpdate_modal_data((prevData) => ({
      ...prevData,
      id_vendor: value,
    }));
  };

  return (
    <>
      <Modal show={add_data_modal_Show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Driver Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="homephonenumber">
              <Form.Label>Home Number *</Form.Label>
              <Form.Control
                type="number"
                name="homephonenumber"
                value={update_modal_data.homephonenumber || ""}
                onChange={handleChange}
                isInvalid={!!errors.homephonenumber}
              />
              <Form.Control.Feedback type="invalid">
                {errors.homephonenumber}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="joiningDate">
              <Form.Label>Joining Date *</Form.Label>
              <Form.Control
                type="date"
                name="joiningDate"
                value={update_modal_data.joiningDate || ""}
                onChange={handleChange}
                isInvalid={!!errors.joiningDate}
              />
              <Form.Control.Feedback type="invalid">
                {errors.joiningDate}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="tempaddress">
              <Form.Label>Temporary Address *</Form.Label>
              <Form.Control
                type="text"
                name="tempaddress"
                value={update_modal_data.tempaddress || ""}
                onChange={handleChange}
                isInvalid={!!errors.tempaddress}
              />
              <Form.Control.Feedback type="invalid">
                {errors.tempaddress}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="country">
              <Form.Label>Country *</Form.Label>
              <Form.Control
                as="select"
                name="country"
                value={update_modal_data.country || ""}
                onChange={handleChange}
                isInvalid={!!errors.country}
              >
                <option value="">Select Country</option>
                {countries.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.country}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="state">
              <Form.Label>State *</Form.Label>
              <Form.Control
                as="select"
                name="state"
                value={update_modal_data.state || ""}
                onChange={handleChange}
                isInvalid={!!errors.state}
              >
                <option value="">Select State</option>
                {states.map((state) => (
                  <option key={state.code} value={state.code}>
                    {state.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.state}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="city">
              <Form.Label>City *</Form.Label>
              <Form.Control
                as="select"
                name="city"
                value={update_modal_data.city || ""}
                onChange={handleChange}
                isInvalid={!!errors.city}
              >
                <option value="">Select City</option>
                {cities.map((city) => (
                  <option key={city.code} value={city.name}>
                    {city.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.city}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="permanentaddress">
              <Form.Label>Permanent Address *</Form.Label>
              <Form.Control
                type="text"
                name="permanentaddress"
                value={update_modal_data.permanentaddress || ""}
                onChange={handleChange}
                isInvalid={!!errors.permanentaddress}
              />
              <Form.Control.Feedback type="invalid">
                {errors.permanentaddress}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="id_user">
              <Form.Label>User *</Form.Label>
              <Form.Control
                as="select"
                name="id_user"
                value={update_modal_data.id_user || ""}
                onChange={handleUserChange}
                isInvalid={!!errors.id_user}
              >
                <option value="">Select User</option>
                {users.map((user) => (
                  <option key={user._id} value={user._id}>
                    {user.email}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.id_user}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Assign Vendor *</Form.Label>
              <Form.Control
                as="select"
                name="id_vendor"
                value={update_modal_data.id_vendor || ""}
                onChange={handleVendorChange}
                isInvalid={!!errors.id_vendor}
              >
                <option value="">Select Vendor</option>
                {vendors.map((vendor) => (
                  <option key={vendor._id} value={vendor._id}>
                    {vendor.vendorname || vendor.id_user?.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.id_hub}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Location</Form.Label>
              <LoadScript
                googleMapsApiKey={process.env.REACT_APP_BASE_API}
                libraries={["places"]}
              >
                <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                  <Form.Control
                    type="text"
                    placeholder="Search places..."
                    value={update_modal_data.location || ""}
                    onChange={(e) =>
                      setUpdate_modal_data({
                        ...update_modal_data,
                        location: e.target.value,
                      })
                    }
                  />
                </Autocomplete>
              </LoadScript>
            </Form.Group>

            <Form.Group className="mb-3" controlId="longitude">
              <Form.Label>Longitude</Form.Label>
              <Form.Control
                type="text"
                name="longitude"
                value={update_modal_data.coordinates?.longitude || ""}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="latitude">
              <Form.Label>Latitude</Form.Label>
              <Form.Control
                type="text"
                name="latitude"
                value={update_modal_data.coordinates?.latitude || ""}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="status">
              <Form.Label>Status *</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={update_modal_data.status || ""}
                onChange={handleChange}
                isInvalid={!!errors.status}
              >
                <option value="">Select Status</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.status}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Driver License *</Form.Label>
              <Form.Control
                type="file"
                name="driverlicense"
                onChange={handleChange}
                accept="image/*"
              />
              <Form.Control.Feedback type="invalid">
                {errors.driverlicense}
              </Form.Control.Feedback>
            </Form.Group>

            {(update_modal_data.driverlicense &&
              update_modal_data.driverlicense[0]) ||
            select_data.driverlicense?.url ? (
              <Form.Group className="mb-3">
                <Form.Label>Driver License Image Preview</Form.Label>
                <div className="d-flex justify-content-center">
                  <img
                    src={
                      update_modal_data.driverlicense &&
                      update_modal_data.driverlicense[0]
                        ? URL.createObjectURL(
                            update_modal_data.driverlicense[0]
                          )
                        : select_data.driverlicense?.url
                    }
                    alt="Driver License Image"
                    style={{ maxWidth: "50%", height: "15%" }}
                  />
                </div>
              </Form.Group>
            ) : null}

            <Form.Group className="mb-3">
              <Form.Label>Govt. Identification *</Form.Label>
              <Form.Control
                type="file"
                name="governmentidentification"
                onChange={handleChange}
                accept="image/*"
              />
              <Form.Control.Feedback type="invalid">
                {errors.governmentidentification}
              </Form.Control.Feedback>
            </Form.Group>
            {(update_modal_data.governmentidentification &&
              update_modal_data.governmentidentification[0]) ||
            select_data.governmentidentification?.url ? (
              <Form.Group className="mb-3">
                <Form.Label>Identification Image Preview</Form.Label>
                <div className="d-flex justify-content-center">
                  <img
                    src={
                      update_modal_data.governmentidentification &&
                      update_modal_data.governmentidentification[0]
                        ? URL.createObjectURL(
                            update_modal_data.governmentidentification[0]
                          )
                        : select_data.governmentidentification?.url
                    }
                    alt="Identification Image"
                    style={{ maxWidth: "50%", height: "15%" }}
                  />
                </div>
              </Form.Group>
            ) : null}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-sm"
            onClick={handleUpdate}
          >
            Update Driver
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
