import { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import { Country, State, City } from "country-state-city";
import { LoadScript, Autocomplete } from "@react-google-maps/api";

export default function Add_data_modal({
  select_data,
  open_update_data_modal,
  onUpdateHub,
}) {
  const [ren, setRen] = useState(false);
  const [update_modal_data, setUpdate_modal_data] = useState(select_data);
  const [add_data_modal_Show, set_update_data_modal_Show] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [selectedStateCode, setSelectedStateCode] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (select_data) {
      setUpdate_modal_data(select_data);
      setSelectedCountryCode(select_data.country || "");
      setSelectedStateCode(select_data.state || "");
    }
  }, [select_data]);

  useEffect(() => {
    set_update_data_modal_Show(open_update_data_modal.modal_open);
  }, [open_update_data_modal]);

  const handleClose = () => {
    set_update_data_modal_Show(false);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "latitude" || name === "longitude") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        coordinates: { ...prevData.coordinates, [name]: value },
      }));
    } else if (name === "hubimage") {
      setUpdate_modal_data({ ...update_modal_data, [name]: files });
    } else {
      setUpdate_modal_data({ ...update_modal_data, [name]: value });
    }
    if (name === "country") {
      setSelectedCountryCode(value);
    }

    if (name === "state") {
      setSelectedStateCode(value);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!update_modal_data.name) newErrors.name = "Hub Name is required";
    if (!update_modal_data.address) newErrors.address = "Address is required";
    if (!update_modal_data.city) newErrors.city = "City is required";
    if (!update_modal_data.area) newErrors.area = "Area is required";
    if (!update_modal_data.country) newErrors.country = "Country is required";
    if (!update_modal_data.state) newErrors.state = "State is required";
    if (
      !update_modal_data.coordinates?.latitude ||
      !update_modal_data.coordinates?.longitude
    )
      newErrors.coordinates = "Location is required";
    if (!update_modal_data.hubimage)
      newErrors.hubimage = "Hub Image is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleUpdate = async () => {
    if (!validateForm()) {
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      Swal.fire("Unauthorized!");
      return;
    }
    Swal.fire({
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { _id } = update_modal_data; // Ensure id is defined
          if (!_id) {
            throw new Error("ID is not defined");
          }

          const formData = new FormData();
          for (const key in update_modal_data) {
            if (update_modal_data.hasOwnProperty(key)) {
              if (key === "coordinates") {
                formData.append(
                  "coordinates[longitude]",
                  update_modal_data.coordinates.latitude || ""
                );
                formData.append(
                  "coordinates[latitude]",
                  update_modal_data.coordinates.longitude || ""
                );
              } else if (key === "hubimage" && update_modal_data[key]) {
                formData.append(key, update_modal_data[key][0]);
              } else {
                formData.append(key, update_modal_data[key]);
              }
            }
          }
          const res = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/v1/hub/${_id}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (res.data.success) {
            Swal.fire("Update success", "", "success");
            onUpdateHub(update_modal_data);
            setRen(!ren);
          }
        } catch (error) {
          Swal.fire("Update failed", "", "error");
          console.error("Error updating hub:", error);
        }
        set_update_data_modal_Show(false);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const onLoad = (autoComp) => {
    setAutocomplete(autoComp);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry && place.geometry.location) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        setUpdate_modal_data((prevData) => ({
          ...prevData,
          coordinates: { latitude: lat, longitude: lng },
        }));
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      const countryData = Country.getAllCountries().map((country) => ({
        name: country.name,
        code: country.isoCode,
      }));
      setCountries(countryData);
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    if (selectedCountryCode) {
      const stateData = State.getStatesOfCountry(selectedCountryCode).map(
        (state) => ({
          name: state.name,
          code: state.isoCode,
        })
      );
      setStates(stateData);
      setCities([]); // Clear cities when country changes
    }
  }, [selectedCountryCode]);

  useEffect(() => {
    if (selectedStateCode && selectedCountryCode) {
      const cityData = City.getCitiesOfState(
        selectedCountryCode,
        selectedStateCode
      ).map((city) => ({
        name: city.name,
        code: city.name,
      }));
      setCities(cityData);
    }
  }, [selectedStateCode, selectedCountryCode]);

  return (
    <>
      <Modal show={add_data_modal_Show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Hub</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Hub Name *</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={update_modal_data.name || ""}
                onChange={handleChange}
              />
              {errors.name && (
                <small className="text-danger">{errors.name}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Address *</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={update_modal_data.address || ""}
                onChange={handleChange}
              />
              {errors.address && (
                <small className="text-danger">{errors.address}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Country *</Form.Label>
              <Form.Control
                as="select"
                name="country"
                value={update_modal_data.country || ""}
                onChange={handleChange}
              >
                <option value="">Select Country</option>
                {countries.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </Form.Control>
              {errors.country && (
                <small className="text-danger">{errors.country}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>State *</Form.Label>
              <Form.Control
                as="select"
                name="state"
                value={update_modal_data.state || ""}
                onChange={handleChange}
              >
                <option value="">Select State</option>
                {states.map((state) => (
                  <option key={state.code} value={state.code}>
                    {state.name}
                  </option>
                ))}
              </Form.Control>
              {errors.state && (
                <small className="text-danger">{errors.state}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>City *</Form.Label>
              <Form.Control
                as="select"
                name="city"
                value={update_modal_data.city || ""}
                onChange={handleChange}
              >
                <option value="">Select City</option>
                {cities.map((city) => (
                  <option key={city.code} value={city.name}>
                    {city.name}
                  </option>
                ))}
              </Form.Control>
              {errors.city && (
                <small className="text-danger">{errors.city}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Longitude</Form.Label>
              <Form.Control
                type="text"
                name="longitude"
                value={update_modal_data.coordinates?.longitude || ""}
                onChange={handleChange}
              />
              {errors.coordinates && (
                <small className="text-danger">{errors.coordinates}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Latitude</Form.Label>
              <Form.Control
                type="text"
                name="latitude"
                value={update_modal_data.coordinates?.latitude || ""}
                onChange={handleChange}
              />
              {errors.coordinates && (
                <small className="text-danger">{errors.coordinates}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Location *</Form.Label>
              <LoadScript
                googleMapsApiKey={process.env.REACT_APP_BASE_API}
                libraries={["places"]}
              >
                <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                  <input
                    type="text"
                    placeholder="Search places..."
                    className="form-control"
                  />
                </Autocomplete>
              </LoadScript>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Area *</Form.Label>
              <Form.Control
                type="number"
                name="area"
                value={update_modal_data.area || ""}
                onChange={handleChange}
              />
              {errors.area && (
                <small className="text-danger">{errors.area}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Hub Image *</Form.Label>
              <Form.Control
                type="file"
                name="hubimage"
                accept="image/*"
                onChange={handleChange}
              />
              {errors.hubimage && (
                <small className="text-danger">{errors.hubimage}</small>
              )}
            </Form.Group>

            {/* Image Preview */}
            {(update_modal_data.hubimage && update_modal_data.hubimage[0]) ||
            select_data.hubimage?.url ? (
              <Form.Group className="mb-3">
                <Form.Label>Image Preview</Form.Label>
                <div className="d-flex justify-content-center">
                  <img
                    src={
                      update_modal_data.hubimage &&
                      update_modal_data.hubimage[0] instanceof File
                        ? URL.createObjectURL(update_modal_data.hubimage[0])
                        : select_data.hubimage?.url
                    }
                    alt="Hub Image"
                    style={{ maxWidth: "50%", height: "30%" }}
                    onLoad={() => {
                      if (
                        update_modal_data.hubimage &&
                        update_modal_data.hubimage[0] instanceof File
                      ) {
                        URL.revokeObjectURL(
                          URL.createObjectURL(update_modal_data.hubimage[0])
                        );
                      }
                    }}
                  />
                </div>
              </Form.Group>
            ) : null}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-sm"
            onClick={handleUpdate}
          >
            Update Hub
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
