import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import FilterModal from "../Filter Modal/FilterModal";
import { counter } from "@fortawesome/fontawesome-svg-core";

export default function DataTable_Component({
  apidata,
  columns,
  searchBy,
  onTotalsChange,
  setTotalOrderFromOrders,
  setDeliveredCountFromOrders,
  setUndeliveredOrdersFromOrders,
  setAmountFromOrders,
}) {
  const [dataFilter, setDataFilter] = useState("");
  const [hubFilter, setHubFilter] = useState("");
  const [userFilter, setUserFilter] = useState("");
  const [vehicleFilter, setVehicleFilter] = useState("");
  const [clientFilter, setClientFilter] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const pageSize = 10;

  const handlePageChange = (page) => {
    fetchOrders(page);
  };
  const fetchOrders = async (page) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }

      const params = new URLSearchParams();
      params.append("page", page);

      if (hubFilter) params.append("id_hub", hubFilter);
      if (userFilter) params.append("id_user", userFilter);
      if (vehicleFilter) params.append("id_vehicle", vehicleFilter);
      if (clientFilter) params.append("id_client", clientFilter);
      if (fromDate) params.append("fromDate", fromDate);
      if (toDate) params.append("toDate", toDate);

      const endpoint = `${
        process.env.REACT_APP_BASE_URL
      }/api/v1/orders?${params.toString()}`;

      const response = await axios.get(endpoint, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.success) {
        const orders = response.data.orders || [];
        let filteredOrders = orders;

        // Apply filters to orders if a dataFilter exists
        if (dataFilter) {
          const searchValue = dataFilter.toLowerCase();
          filteredOrders = filteredOrders.filter((item) => {
            return (
              (item.name && item.name.toLowerCase().includes(searchValue)) ||
              item.id_user?.name?.toLowerCase().includes(searchValue) ||
              item.id_vehicle?.numberplate?.toLowerCase().includes(searchValue)
            );
          });
        }

        setFilteredData(filteredOrders);
        setTotalRecords(response.data.totalOrders);

        // Check if summary is available in the response and update totals
        if (response.data.summary) {
          const {
            totalOrders,
            totalDelivered,
            totalUndelivered,
            totalAmounts,
          } = response.data.summary;
          // Update the summary for `index.js`
          setTotalOrderFromOrders(totalOrders);
          setDeliveredCountFromOrders(totalDelivered);
          setAmountFromOrders(totalAmounts);
          setUndeliveredOrdersFromOrders(totalUndelivered || 0);
        } else {
          console.error(
            "Summary data is missing in the response from index.js API."
          );
        }
      } else {
        console.error("API response indicates failure:", response.data);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    fetchOrders(1);
  }, [
    hubFilter,
    userFilter,
    vehicleFilter,
    clientFilter,
    fromDate,
    toDate,
    dataFilter,
  ]);

  const applyFilters = () => setShowFilterModal(false);
  const removeFilters = () => {
    setShowFilterModal(false);
    setClientFilter("");
    setHubFilter("");
    setVehicleFilter("");
    setUserFilter("");
    setFromDate("");
    setToDate("");
    setDataFilter(""); // Clear the search filter
    fetchOrders(1);
  };

  return (
    <div className="table-responsive">
      <div className="row justify-content-between mb-2">
        <div className="col-md-9 mb-2">
          <button
            className="btn btn-primary"
            onClick={() => setShowFilterModal(true)}
          >
            Filter
          </button>
        </div>
      </div>
      <DataTable
        columns={columns}
        data={filteredData}
        pagination
        fixedHeader
        highlightOnHover
        responsive
        paginationPerPage={pageSize}
        paginationRowsPerPageOptions={[10]}
        paginationServer
        onChangePage={handlePageChange}
        paginationTotalRows={totalRecords}
        // paginationTotalRows={1000} //TODO: to be updated when api starts providing the information
      />
      <FilterModal
        show={showFilterModal}
        onHide={() => setShowFilterModal(false)}
        applyFilters={applyFilters}
        removeFilters={removeFilters}
        apidata={apidata}
        hubFilter={hubFilter}
        setHubFilter={setHubFilter}
        clientFilter={clientFilter}
        setClientFilter={setClientFilter}
        vehicleFilter={vehicleFilter}
        setVehicleFilter={setVehicleFilter}
        userFilter={userFilter}
        setUserFilter={setUserFilter}
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
      />
    </div>
  );
}
